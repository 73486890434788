import { Entity } from "../../Entity";
import { Parameter } from "./parameters";

export class Category extends Entity {
  constructor(
    public id: number, //
    public url: string,
    public name: string,
    public isSearchable: 0 | 1,
    public cover: string | undefined,
    public parameters: Parameter[] = []
  ) {
    super(id);
  }

  public async getParameters(): Promise<Parameter[]> {
    return this.parameters;
  }

  public getName(): string {
    return this.name;
  }

  public getUrl(): string {
    return this.url;
  }

  public is(categoryId: number): boolean {
    return this.getId() === categoryId;
  }
}
