export default {
  chat: {
    heading: "Správy",
    pageTitle: "@:views.chat.heading",
    deletedUserNickname: "Zmazaný používateľ",
    inputPlaceholder: "Napíš správu…",
    collectorsProfile: "Profil zberateľa",
    error: {
      connectionNotCreated: "Nepodarilo sa načítať správy.",
      streamError: "Nastala chyba pri prenose dát.",
      emptyMsg: "Najprv napíš svoju správu.",
      messageTooLong: "Správa môže byť najviac { maxLength } znakov dlhá.",
      buddyDoesntExists: "Odovzdanie správy sa nepodarilo, účet zberateľa bol zrejme zrušený.",
      chatClosed: "Používateľ bol zmazaný, nemôžeš mu teda napísať správu.",
      messageNotSent: "Správu sa nepodarilo odoslať.",
    },
    placeholderMsg: {
      noConversationCreated: "Zatiaľ si si s nikým ešte nenapísal. Napíš zberateľom cez ich verejné predmety alebo priamo cez profil.",
      noConversationActive: "Začni tým, že si vyberieš konverzáciu&nbsp;vľavo.",
      noMessageInConversation: "Zatiaľ si si s používateľom nevymenil žiadnu správu. Buď prvý, kto napíše!",
    },
    card: {
      actions: {
        archive: "Archívovať chat",
      },
    },
    systemMsg: {
      userDeleted: "Účet zberateľa bol zmazaný.",
    },
    message: {
      myOriginFromItem: "Píšem ohľadom predmetu",
      myOriginIsDeletedItem: "Píšem ohľadom zmazaného predmetu",
      buddyOriginFromItem: "Zberateľ ti píše ohľadom predmetu",
      buddyOriginIsDeletedItem: "Zberateľ ti píše ohľadom zmazaného predmetu",
      byMe: "Od teba",
      byBuddy: "Od",
      byDeletedBuddyPlaceholder: "zmazaného používateľa",
    },
    archive: {
      title: "Archivácia konverzácie",
      text: "Naozaj chceš archivovať konverzáciu?",
      yesBtn: "áno",
      noBtn: "nie",
      successMsg: "Konverzácia bola archivovaná.",
    },
    unsubscribe: {
      heading: "Bol si úspešne odhlásený",
      text: "V prípade, že si to rozmyslíš a budeš chcieť dostávať upozornenia na svoje správy, môžeš to urobiť v",
      linkText: "nastavení svojho profilu",
      buttonPublic: "Späť domov",
      buttonUser: "Späť na nástenku",
    },
    unsubscribeError: {
      heading: "Odhlásenie e-mailu sa nepodarilo",
      text: "Tento <strong>odkaz už nie je platný</strong>. Prosím, skús nájsť iný v&nbsp;novšom e-maile. Alebo si toto nastavenie môžeš zmeniť priamo v ",
      linkText: "nastavení svojho profilu",
      buttonPublic: "Späť domov",
      buttonUser: "Späť na nástenku",
    },
  },
};
