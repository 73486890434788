export default {
  errors: {
    unknown: "Ups. Operácia sa nepodarila. Ak problémy pretrvávajú, kontaktujte nás prosím.",
    error400: "Chybný požiadavok (status 400).",
    error403: "Nemáš oprávnenie vykonať túto operáciu (status 403).",
    general: "Operáciu sa nepodarilo vykonať. Chybu sme zaznamenali a pokúsime sa ju čo najskôr opraviť. Skús to prosím znova čoskoro.",
    internetConnectionLost: "Nepodarilo sa načítať údaje. Si pripojený na internet?",
  },
  close: "Zavrieť",
  doNotShowNextTime: "Nabudúce už nezobrazovať",
  yes: "Áno",
  no: "Zrušiť",
  chart: {
    period: {
      month: "1 mesiac",
      halfYear: "6 mesiacov",
      year: "1 rok",
      full: "Maximálne",
    },
  },
  date: {
    today: "dnes",
    yesterday: "včera",
    beforeXDays: "pred { days } dňami",
  },
  pagination: {
    outOf: "z",
    pages: "str.",
  },
  negotiablePrice: "Dohodou",
  notForSale: "Nie je na predaj",
  piecesUnit: "ks",
  sort: {
    default: "Radenie",
    dateAsc: "Najstaršie",
    dateDesc: "Najnovšie",
    priceAsc: "Najlacnejšie",
    priceDesc: "Najdrahšie",
    alphabetAsc: "Názov A-Z",
    alphabetDesc: "Názov Z-A",
    relevance: "Relevancia",
    likes: "Najobľúbenejšie",
  },
  versionBar: {
    text: "<strong>Nová verzia</strong> webu je k dispozícii.",
    btnDesktop: "Aktualizovať stránku",
    btnMobile: "Aktualizovať",
  },
  categorySelectPlacehoder: "Vyber kategóriu",
  copiedToClipboard: "Skopírované do schránky.",
};
