export enum CategoryId {
  lego = 1,
  alcohol = 3,
  watch = 2,
  shoes = 4,
  art = 5,
  furniture = 6,
  jewellery = 7,
  coins = 8,
  modelling = 9,
  stamps = 10,
  funkoPop = 11,
  vinyl = 12,
  musicalInstrument = 13,
  autograph = 14,
  perfume = 15,
  classicCar = 16,
  comics = 17,
  postcard = 18,
  weapon = 19,
  magic = 20,
  pokemon = 21,
  sportsCards = 22,
  book = 23,
  purse = 24,
  porcelain = 25,
  moviePoster = 26,
  boardgame = 27,
  banknote = 28,
  badge = 29,
  others = 30,
  legoMinifigs = 31,
  starWarsCards = 32,
}

// Database cover images - used on database page.
export const CategoryCovers = new Map<number, string>([
  [CategoryId.lego, "lego"],
  [CategoryId.alcohol, "alcohol"],
  [CategoryId.watch, "watch"],
  [CategoryId.shoes, "shoes"],
  [CategoryId.vinyl, "vinyl"],
  [CategoryId.boardgame, "boardgame"],
  [CategoryId.legoMinifigs, "lego-minifigs"],
]);
