import { getUser, hidePrices as savePricesHidden, showPrices as savePricesShown, User } from "@/services/user";
import { createProfileInitializationLink, router } from "@/router";
import { ref } from "vue";

const arePricesVisible = ref<boolean>(false);

export function usePriceVisibility() {
  const user = ref<User>();
  getUser().then((u) => (user.value = u));
  arePricesVisible.value = user.value?.hasVisiblePrices() ?? true;

  const hidePrices = () => {
    user.value?.setShowPrices(false);
    arePricesVisible.value = false;
    savePricesHidden(); // Save settings in BE.
  };

  const showPrices = () => {
    arePricesVisible.value = true;
    user.value?.setShowPrices(true);
    savePricesShown(); // Save settings in BE.
  };

  return {
    hidePrices,
    showPrices,
    arePricesVisible,
  };
}

export function useRedirectToNicknameDialog() {
  const redirectToNicknameDialog = (): void => {
    const link = createProfileInitializationLink();
    router.push(link);
  };

  return {
    redirectToNicknameDialog,
  };
}
