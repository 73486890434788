export default {
  publicLayout: {
    loginButton: "Prihlásiť sa",
    footer: {
      taxId: "IČ",
      menu: "Menu",
      toTop: "späť",
      gdprLink: "Zásady ochrany osobných údajov",
      cookiesLink: "Nastavenia cookies",
      tacLink: "Všeobecné obchodné podmienky",
    },
    menu: {
      btnTitle: "Menu",
      magazine: "Magazín",
      about: "O nás",
      contacts: "Kontakty",
      publicItemsList: "@:appLayout.menu.publicItemsList",
    },
  },
};
