import { createI18n } from "vue-i18n";
import csTexts from "@/locales/cs";
import skTexts from "@/locales/sk";
import translations from "@/locales/translations.json";
import { createGettext } from "vue3-gettext";
import { useUserStore, store as pinia } from "@/store";

export enum LanguageCode {
  cs = "cs",
  sk = "sk",
}

export enum CountryCode {
  Cz = "cz",
  Sk = "sk",
}

const LanguageCode2NameMap = new Map<LanguageCode, string>([
  [LanguageCode.cs, "čeština"],
  [LanguageCode.sk, "slovenština"],
]);

export const CountryCode2LanguageCodeMap = new Map<CountryCode, LanguageCode>([
  [CountryCode.Cz, LanguageCode.cs],
  [CountryCode.Sk, LanguageCode.sk],
]);

const availableLanguages = Object.fromEntries([...LanguageCode2NameMap.entries()].map(([code, name]) => [code, name])); // { "cs": "čeština", "sk": "slovenština", ...}

export const FallbackLanguageCode = LanguageCode.cs;
export const FallbackCountryCode = CountryCode.Cz;

/** @deprecated */
const PluralRules = {
  [LanguageCode.cs]: function (count: number): number {
    if (count === 0) {
      return 0;
    } else if (count === 1) {
      return 1;
    } else if (count < 5) {
      return 2;
    } else {
      return 3;
    }
  },
};

/** @deprecated */
export const i18n = createI18n({
  locale: LanguageCode.cs,
  fallbackLocale: FallbackLanguageCode,
  warnHtmlMessage: false,
  legacy: false,
  globalInjection: true,
  messages: {
    [LanguageCode.cs]: csTexts,
    [LanguageCode.sk]: skTexts,
  },
  pluralRules: PluralRules,
  missing: (locale, key) => {
    console.error(`Translation missing for key: "${key}" in locale: "${locale}"`);
    return key;
  },
});

/** @deprecated */
export const translate = i18n.global.t;

export const gettext = createGettext({
  availableLanguages,
  defaultLanguage: FallbackLanguageCode,
  translations,
});

export const setCountry = (country: CountryCode): void => {
  const userStore = useUserStore(pinia);
  userStore.setCountryCode(country);

  const language = CountryCode2LanguageCodeMap.get(country);
  if (language === undefined) {
    throw new Error("DEV: Should not happen.");
  }
  setLanguage(language);
};

export const setLanguage = (language: LanguageCode): void => {
  i18n.global.locale.value = language;
  gettext.current = language;
  document.documentElement.lang = language;
};
