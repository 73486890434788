export default {
  profileInitialization: {
    nickname: {
      label: "Zvol si přezdívku",
      placeholder: "Tvoje přezdívka",
      help: "Přezdívka, pod kterou budeš fungovat v našem sběratelském světě.",
      error: {
        taken: "Přezdívku už někdo používá. Nemáš dvojníka? :) Zkus prosím nějakou jinou.",
        wrongFormat: "Chápeme, krátké přezdívky jsou cool, uživatelé tě ale chtějí rozpoznat! Minimální počet znaků je 3",
      },
    },
    favouriteCategories: {
      label: "@:views.userSettings.favouriteCategories.title",
      placeholder: "@:views.userSettings.favouriteCategories.placeholder",
      help: "@:views.userSettings.favouriteCategories.help",
    },
    submit: "Dokončit",
  },
};
