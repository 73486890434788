import { CategoryFilterValue, PublicCollectionItemsFilter } from "./PublicCollectionItemsFilter";
import { CategoryId, OnlyPublicProperties } from "@/types";
import { forceAscRangeOrder } from "@/utilities";
import { requireUser } from "@/services/user";
import { ValueAllCategories } from "../collection/categories";
import { router } from "@/router";

export function createApiParams(filter: OnlyPublicProperties<PublicCollectionItemsFilter>): Record<string, number | CategoryId | CategoryId[] | string | boolean | undefined> {
  const q = filter.fulltext;
  const fulltext = q === undefined || q === "" ? undefined : q;
  const [minPrice, maxPrice] = forceAscRangeOrder(filter.minPrice, filter.maxPrice);

  const params = <Record<string, number | CategoryId[] | string | boolean | undefined>>{
    fulltext,
    minPrice,
    maxPrice,
    onlyForSale: filter.onlyForSale ? 1 : 0,
    includeNegotiablePrice: filter.includeNegotiablePrice ? 1 : 0,
    sortOrder: filter.sortOrder,
    page: filter.page,
    perPage: filter.perPage,
    excludeMy: filter.excludeMy ? 1 : 0,
    nickname: filter.nickname,
  };

  if (filter.isSelectedFavouriteCategories()) {
    const user = requireUser();
    params["categoryId"] = user.data.favouriteCategories.map((c) => c.getId());
  } else {
    params["categoryId"] = filter.categoryId === ValueAllCategories ? undefined : filter.categoryId;
  }

  return params;
}

export function createUrlParams(filter: OnlyPublicProperties<PublicCollectionItemsFilter>) {
  const q = filter.fulltext;
  const fulltext = q === undefined || q === "" ? undefined : q;
  const [minPrice, maxPrice] = forceAscRangeOrder(filter.minPrice, filter.maxPrice);

  const params = <Record<string, number | CategoryFilterValue | string | undefined>>{
    categoryId: filter.categoryId,
    fulltext,
    minPrice,
    maxPrice,
    onlyForSale: filter.onlyForSale ? 1 : 0,
    includeNegotiablePrice: filter.includeNegotiablePrice ? 1 : 0,
    sortOrder: filter.sortOrder,
    page: filter.page,
    perPage: filter.perPage,
  };

  return params;
}

export function updateCurrentUrl(filter: OnlyPublicProperties<PublicCollectionItemsFilter>) {
  const params = createUrlParams(filter);
  router.replace({
    path: router.currentRoute.value.path,
    query: params,
  });
}
