export default {
  landingPages: {
    heroCta: "Vytvoř si sbírku",
    sliderHeadingLine1: "Sbírka",
    sliderHeadingLine2: "ve třech krocích",
    watches: {
      heading: "TVÉ RADOSTI&nbsp;NA<br>JEDNOM MÍSTĚ",
      text: "Jsi hodinkový nadšenec? Máš doma tři plné hodinkovnice nebo jen pár speciálních kousků? V&nbsp;každém případě&nbsp;–&nbsp;vítej mezi námi. Objev nový svět chronobláznů: Vytvoř a&nbsp;doplňuj svou unikátní sbírku, čti si novinky a&nbsp;vyzkoušej všechny funkce, které jsme pro tebe připravili.",
      textSeo: "<p>Říkají vám něco značky hodinek Rolex, Breitling, Cartier nebo Tudor? V tom případě jsou sběratelské hodinky jistě vaší vášní. Užíváte se precizně ručně vyrobených hodinek s mnoha komplikacemi, mechanickým strojkem a exkluzivním vzhledem? Krásné je, že má každý sběratel trochu jiný vkus. Někdo radši potápěčské hodinky, někdo pilotky jiný digitálky ve stylu G shock. Ať je to jakkoliv, všichni hodinkoví nadšenci mají společného jedno: Lásku k dobré hodinařině.</p>",
      slides: {
        slide1: {
          heading: "Měj přehled<br />o&nbsp;svých hodinkách",
          text: "Zajímáš se o&nbsp;hodinky nebo jen máš rád pořádek ve&nbsp;svých věcech? Přidávej si&nbsp;hodinky i&nbsp;jiné předměty do svého portfolia a&nbsp;měj veškeré informace neustále u&nbsp;sebe.",
          cta: "Vytvoř si sbírku",
        },
        slide2: {
          heading: "Máš rád čísla? <br />Sleduj je.",
          text: "Využívej grafů, přehledných tabulek s&nbsp;parametry nebo bilance hodnoty tvé sbírky. Sám si&nbsp;urči hodnotu tvé sbírky hodinek.",
          cta: "Vytvoř si sbírku",
        },
        slide3: {
          heading: "Buď s&nbsp;námi vždy <br />v&nbsp;obraze",
          text: "Přinášíme ti informace ze světa hodinek, včetně nejčerstvějších novinek. Na jednom místě tak najdeš vše, co tě o&nbsp;tvé radosti zajímá.",
          cta: "Vytvoř si sbírku",
        },
      },
    },
    faq: {
      heading: "čtyři nejčastější dotazy:",
      isItFree: {
        question: "Je Moje sbírka zdarma?",
        answer: "<p>Ano, svoji sbírku se&nbsp;všemi funkcionalitami máš k&nbsp;dispozici plně zdarma.</p>",
      },
      howManyCategories: {
        question: "Kolik sběratelských kategorií pokrýváte?",
        answer: "<p>Hlavních kategorií je něco přes 30 a&nbsp;nové stále přibývají. I&nbsp;tak je sbírka postavena na tom, že&nbsp;si můžeš přidat cokoliv, co ti dělá radost nebo o&nbsp;čem bys rád měl přehled. Pokud jde o&nbsp;naše kategorie, najdeš u&nbsp;nich přesné specifikace, navíc k&nbsp;nim postupně doplňujeme kompletní databáze.</p>",
      },
      profileVisibility: {
        question: "Kdo vidí můj profil a&nbsp;předměty?",
        answer: "<p>Sbírka je chráněná tak, aby nikdo kromě tebe neviděl, co si do sbírky přidáváš. Nemusíš se tak bát o&nbsp;svá data.</p>",
      },
      extraFeatures: {
        question: "Umí sbírka ještě něco?",
        answer: "<p>Sbírka má plno funkcionalit. Kromě samotného přidávání předmětu a&nbsp;vytváření sbírek můžeš sledovat aktuální grafy vývoje ceny předmětů, sledovat novinky a&nbsp;doporučení nebo číst magazín. U&nbsp;toho ale nekončíme. Postupně rozšiřujeme sbírku o&nbsp;další a&nbsp;další funkcionality.</p>",
      },
      cta: "Vytvoř si sbírku",
    },
  },
};
