export default {
  addItem: {
    heading: "Pridaj si predmet",
    favouriteCategories: "Obľúbené kategórie",
    loadingCategories: "Načítavam kategórie...",
    itemName: "Názov predmetu / ID",
    itemVisibilityLabel: "Verejný predmet",
    itemVisibilityTooltip: "@:views.itemDetail.params.isItemPublicTooltip",
    createItemButton: "Pridať do zbierky",
    creatingItem: "Ukladám predmet...",
    created: "Predmet vytvorený.",
    creationFailed: "Predmet sa nepodarilo vytvoriť.",
    errors: {
      noCategory: "Vyber kategóriu.",
      noItemName: "Zadaj názov alebo ID predmetu.",
    },
  },
};
