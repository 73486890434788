export default {
  userSettings: {
    heading: "Nastavenia profilu",
    imageEditBtn: "Upraviť foto",
    avatarUploadInProgress: "Nahrávam...",
    avatarUploadFailed: "Nahrávanie obrázka zlyhalo.",
    headings: {
      personalData: "Osobné údaje",
      links: "Odkazy",
      bio: "Bio",
      notifications: "E-mailové upozornenia",
      credentials: "Prihlasovacie údaje",
    },
    fieldLabels: {
      publicNickname: "Prezývka",
      publicName: "Meno",
      publicEmail: "E-mail",
      publicPhone: "Telefón",
      publicUrlFacebook: "Facebook",
      publicUrlInstagram: "Instagram",
      publicUrlTikTok: "TikTok",
      publicUrlPinterest: "Pinterest",
      publicUrlWeb: "Webová stránka",
      chatNewMessagesNotifications: "Na nové správy",
      email: "E-mail",
      password: "Heslo",
      currentPassword: "Súčasné heslo",
      newPassword: "Nové heslo",
      newPasswordAgain: "Heslo znovu",
    },
    messagesSaved: {
      emailNotificationEnabled: "E-mailové upozornenie zapnuté.",
      emailNotificationDisabled: "E-mailové upozornenie vypnuté.",
    },
    changePasswordButton: "Zmeniť heslo",
    savePasswordButton: "Uložiť",
    passwordChanged: "Gratulujeme! Zmenil(a) si heslo.",
    nicknameTooltip: "Ďalšia možná úprava za { days } dní.",
    errors: {
      publicNameTooLong: "Meno nesmie byť dlhšie ako { maxLength } znakov.",
      nicknameWrongFormat: "Prezývka smie obsahovať len alfanumerické znaky (bez diakritiky) a pomlčky.",
      nicknameWrongLength: "Prezývka musí mať dĺžku { minLength }-{ maxLength } znakov.",
      bioTooLong: "Dĺžka bio je obmedzená na { maxLength } znakov.",
      currentPasswordIncorrect: "Zadané súčasné heslo nesedí. Skús to znova.",
      emptyCurrentPassword: "Vyplň súčasné heslo.",
      passwordsMismatch: "Prepáč, heslá sa neshodujú.",
      badRequest: "Nastavenia sa nepodarilo uložiť. Posielaš správne dáta?",
      notFound: "Tento používateľ nám nie je známy.",
      nicknameTaken: "Prepáč, túto prezývku už používa niekto iný. Skús inú.",
      nicknameTooEarlyChange: "Prezývku zatiaľ nie je možné zmeniť.",
    },
    favouriteCategories: {
      title: "Obľúbené kategórie",
      noCategorySelected: "Nič nevybrané",
      placeholder: "Vyber kategórie",
      help: "Vyber si svoje obľúbené kategórie. Podľa nich ti potom prispôsobíme obsah.",
      dialogCancel: "Zrušiť",
      dialogConfirm: "Uložiť",
      dialogSaving: "Ukladám...",
      savedMsg: "Tvoje obľúbené kategórie boli aktualizované.",
      saveError: "Tvoje obľúbené kategórie sa nepodarilo aktualizovať. Skús to prosím znova alebo nás kontaktuj.",
    },
  },
};
