export default {
  dropdown: {
    selectAllBtn: "Vybrat vše",
    fillCustomValue: "Doplň si vlastní hodnotu.",
    noOptions: "Žádná dostupná možnost.",
    messages: {
      tooShort: "Zadejte alespoň { minSearchLength } znaky.",
      searching: "Vyhledávám...",
      nothingFound: "Nic nebylo nalezeno.",
    },
  },
};
