import { ParameterValue } from "./ParameterValue";
import { createParameterValue } from "./parameterValueFactory";

class Parameter {
  constructor(data) {
    this.id = data.id;
    this.column = data.column;
    this.name = data.name;

    if (data.conditionValue && data.conditionParameter) {
      this.conditions = {
        parameterId: data.conditionParameter,
        valueId: data.conditionValue,
      };
    } else {
      this.conditions = null;
    }

    this.values = data.values.map((rawData) => {
      return createParameterValue(rawData);
    });
  }

  isAvailableFor(parameterId, valueId) {
    if (!this.conditions) {
      return true;
    }

    if (this.conditions.parameterId !== parameterId) {
      return null;
    }

    let v = valueId;
    if (v instanceof ParameterValue) {
      v = v.getId();
    }

    if (this.conditions.valueId === v) {
      return true;
    } else {
      return false;
    }
  }
}

export { Parameter };
