export default {
  cookieConsent: {
    modal: {
      title: "Máme rádi cookies 🍪",
      description: "Abychom ti mohli nabídnout personalizovaný obsah a uložit tvé preference, používáme cookies. Dáš nám zelenou?",
      acceptAllBtn: "Přijmout vše",
      acceptNecessaryBtn: "Přijmout nezbytné",
      showPreferencesBtn: "Nastavení cookies",
      closeIconLabel: "Odmítnout vše",
    },
    preferencesModal: {
      title: "Nastavení cookies",
      acceptAllBtn: "Přijmout vše",
      acceptNecessaryBtn: "Odmítnout vše",
      savePreferencesBtn: "Uložit nastavení",
      sections: {
        description: {
          title: "Co jsou to cookies?",
          description: "Soubory cookies a další technologie nám pomáhají zlepšovat naše služby a pomáhají nám analyzovat výkon webu. V nastavení si můžete vybrat, které cookies můžeme používat. Svůj souhlas můžete kdykoliv odvolat.",
        },
        essentials: {
          title: "Nezbytné cookies pro správné fungování našeho webu.",
          description: "Tyto cookies jsou nezbytně nutné pro správnou funkci webu - zajišťují správné zobrazení, odesílání formulářů, vkládání zboží do košíku a podobně. Technické cookies není možné vypnout.",
        },
        analytics: {
          title: "Sledování návštěvnosti a chování na webu nám pomáhá zlepšovat naše služby.",
          description: "Statistické cookies používáme k vyhodnocování výkonu našeho webu a reklamních kampaní. Pomocí cookies určujeme počet návštěv, zdroje návštěvnosti a chování zákazníků na našem webu - např. informace o tom, co na webu hledáte a které informace jsou pro vás nejdůležitější. Na základě těchto dat můžeme provádět optimalizaci webu a vylepšovat naše služby.",
        },
        ads: {
          title: "Cookies pro cílení a reklamu",
          description: "Tyto soubory cookie se používají k zobrazování reklamy, která vás pravděpodobně bude zajímat na základě vašich zvyků při procházení. Tyto soubory cookie, jsou požadovány námi/nebo poskytovateli reklam, mohou kombinovat informace shromážděné z našich webových stránek s dalšími informacemi, které nezávisle shromáždily z jiných webových stránek, týkající se činností vašeho internetového prohlížeče v rámci jejich reklamní sítě webových stránek.",
        },
        personalization: {
          title: "Personalizace obsahu",
          description: "Tyto cookies nám umožní zobrazit Vám obsah dle údajů, které jsou o Vás dostupné tak, abychom se co nejlépe strefili do Vašich potřeb. Jde zejména o to, jaký obsah jste si zobrazili, či na jakém zařízení na naše stránky přicházíte.",
        },
      },
    },
  },
};
