import { computed } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

export function useUrlAnchor() {
  const router = useRouter();

  const addAnchorToUrl = (anchor: string) => {
    const routeCurrent = router.currentRoute.value;
    router.push({ ...routeCurrent, hash: "#" + anchor });
  };
  const clearAnchorFromUrl = () => router.push({ hash: "" });

  const route = useRoute();
  const currentAnchor = computed(() => route.hash.substring(1));

  return {
    addAnchorToUrl,
    clearAnchorFromUrl,
    currentAnchor,
  };
}
