export default {
  magazine: {
    breadcrumb: "Magazín",
    titleNoCategory: "Magazín",
    titleCategory: "{ categoryName } magazín",
    heading: "Magazín",
    shareBtn: "Sdílet",
    showMoreBtn: "Zobrazit více",
    readLength: "min čtení",
    readMore: "číst více",
    filterPlaceholder: "Kategorie",
    relatedHeading: "Mohlo by tě zajímat",
    relatedSubheading: "Magazín",
    relatedMore: "Více článků",
    banner: {
      heading1: "Tvoje sbírka",
      heading2: "na jednom místě",
      li1: "Sleduj vývoj hodnoty své sbírky",
      li2: "Využívej aktuální databáze",
      li3: "Získej zajímavé tipy pro sběratele",
      cta: "Vytvořit sbírku",
      imgAlt: "Tvoje sbírka na jednom místě",
    },
    widget: {
      error: "Předmět se nepodařilo načíst.",
      currency: "Kč",
      cta: "Detail předmětu",
    },
    survey: {
      loading: "Načítám anketu...",
      votesCount: "hlasů | hlas | hlasy | hlasů",
    },
  },
};
