export default {
  gallery: {
    close: "Zavrieť",
    previous: "Predchádzajúci",
    next: "Ďalší",
    menu: "Možnosti",
    imageAdd: "Pridať fotografiu",
    imageDelete: "Zmazať fotografiu",
    imageSetAsCover: "Nastaviť ako obal",
    coverTag: "obal",
    deleteConfirmation: {
      title: "Odstránenie obrázku",
      msg: "Naozaj chcete odstrániť tento obrázok?",
    },
    noImage: {
      text: "Tvoj predmet čaká na svoj moment slávy! Pridaj si prvú fotku a pochváľ sa ostatným.",
      cta: "@:components.gallery.imageAdd",
    },
  },
};
