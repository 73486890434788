import { useRedirectToNicknameDialog } from "@/composables";
import { createLoginLink, router } from "@/router";
import { checkUserOnInitialPageLoad, resetAuthentication, TokenNotValidException, UserNotEstablishedException } from "@/services/user";
import { defineStore } from "pinia";

export const useAppState = defineStore("appState", {
  state: () =>
    ({
      isStartupUserCheckDone: false,
      isLanguageDetected: false,
    } as {
      isStartupUserCheckDone: boolean;
      isLanguageDetected: boolean;
    }),

  actions: {
    /**
     * Check if there is some userToken saved in browser. If so, try to login stored user.
     */
    startupUserCheck() {
      checkUserOnInitialPageLoad()
        .then((user) => {
          if (user) {
            const route = router.currentRoute.value;
            const redirect = route.query.redirect_url;
            if (typeof redirect === "string") {
              router.push(redirect);
            }
          }
        })
        .catch((e: unknown) => {
          if (e instanceof UserNotEstablishedException) {
            const { redirectToNicknameDialog } = useRedirectToNicknameDialog();
            redirectToNicknameDialog();
          } else if (e instanceof TokenNotValidException) {
            resetAuthentication();
            const route = router.currentRoute.value;
            const isRoutePublic = route.name === undefined || route.meta.public === true; // route.name === undefined ... first page load
            if (!isRoutePublic) {
              router.push(createLoginLink());
            }
          } else {
            throw e;
          }
        })
        .finally(() => {
          this.isStartupUserCheckDone = true;
        });
    },

    /**
     * Are all neccessary steps done? Can we safely start app rendering?
     */
    isAppInitialized(): boolean {
      return this.isStartupUserCheckDone && this.isLanguageDetected;
    },

    markLanguageDetected() {
      this.isLanguageDetected = true;
    },
  },
});
