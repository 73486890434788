export default {
  ebay: {
    heading: "Oceňovač",
    introText: "Nevieš cenu svojich predmetov, alebo si ňou nie si istý? Vytvorili sme pre teba oceňovač. Jednoducho si vyhľadaj, za koľko podobné predmety ponúkajú ostatní a zisti, akú majú vo svete približnú cenu. (eBay)",
    tips: {
      heading: "Tipy na vyhľadávanie",
      point1: "Ak chceš vyhľadať viac variant rovnakého výrazu, uzavri ich do hranatých zátvoriek a oddel ich čiarkou (napríklad: [PSA, BGS])",
      point2: "Pre vylúčenie výrazov z vyhľadávania použite znamienko mínus „-“ (napríklad: LEGO® Star Wars -mandalorian -cruiser)",
      point3: "Použi & pre zhodu iba s výrazmi daného vzoru. Charizard PSA&10",
    },
    form: {
      submit: "Vyhľadať ceny",
      itemType: {
        sold: "Predané predmety",
        active: "Ponúkané predmety",
      },
      queryPlaceholder: "Hľadaj…",
      category: {
        all: "Všetky kategórie",
        sportsCards: "Športové karty",
        baseball: "Baseball",
        football: "Americký futbal",
        soccer: "Futbal",
        basketball: "Basketbal",
        iceHockey: "Hokej",
        golf: "Golf",
        tennis: "Tenis",
        mma: "MMA",
        box: "Box",
        racing: "Závodné",
        cardGames: "Zberateľské kartové hry",
        pokemon: "Pokémon",
        mtg: "Magic: The Gathering",
        yugioh: "Yu-Gi-Oh!",
        lego: "LEGO®",
        vinyls: "Vinylové platne",
        watches: "Hodinky",
        shoes: "Tenisky",
        shoesMen: "Pánske tenisky",
        shoesWomen: "Dámske tenisky",
        stamps: "Poštové známky",
        coins: "Mince",
        comics: "Komiksy",
        postcards: "Pohľadnice",
        funkopop: "Funko-pop",
        weapons: "Historické zbrane",
        games: "Hry",
        arcadeGames: "Automaty & pinball",
        videoGames: "Konzolové hry",
      },
      sort: {
        highestPrice: "Triediť od najvyššej ceny",
        lowestPrice: "Triediť od najnižšej ceny",
        mostRecent: "Triediť od najnovšieho",
      },
      error: {
        emptyQuery: "Vyplň hľadaný výraz.",
      },
    },
    list: {
      heading: "Výsledky vyhľadávania",
      name: "Názov",
      priceCurrent: "Aktuálna cena",
      priceFinal: "Predané za",
      bidsCount: "Aukcie (počet príspevkov)",
      dateStart: "Dátum vystavenia",
      dateEnd: "Dátum predaja",
      emptyMsg: "Nebol nájdený žiadny predmet. Skús upraviť parametre vyhľadávania.",
    },
  },
};
