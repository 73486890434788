import { API, TokenAdd } from "@/services/api2";
import { isFullPrice, Price } from "@/services/prices";

enum CurrencyId {
  Czk = 1,
  Usd = 4,
}
const FallbackCurrencyId = CurrencyId.Czk;

export type Currency = {
  id: number;
  code: string;
  symbol: string;
  isPositionLeft: boolean;
  isSpaceSeparator: boolean;
  decimalPrecisionWhenCurrencySwitch: number;
  decimalSeparator: string;
  thousandsSeparator: string;
  isAvailableForSwitchSelector: boolean;
  ratioThousands: { [currencyId: number]: number };
};

const ApiUrl = import.meta.env.VITE_API_PUBLIC_URL + "currency";

let getAllRequestCache: Promise<Currency[]> | undefined = undefined; // Let's cache API request/response = we load currencies only once pre session.
const getAll = async (): Promise<Currency[]> => {
  if (getAllRequestCache === undefined) {
    getAllRequestCache = API.getPublic<Currency[]>(ApiUrl, {}, TokenAdd.Never).then((response) => response.data);
  }
  return getAllRequestCache;
};

const getById = async (id: number): Promise<Currency> =>
  getAll().then((currencies: Currency[]) => {
    const found = currencies.find((currency) => currency.id === id);
    if (!found) {
      throw new Error("DEV: Currency with id " + id + " not found.");
    }
    return found;
  });

const formatAmount = (amount: number, currency: Currency): string => {
  const numStr = amount.toLocaleString("en");
  const [integerPart, decimalPart] = numStr.split(".");
  const formattedIntegerPart = integerPart.replaceAll(",", currency.thousandsSeparator);

  let formattedNumber = formattedIntegerPart;
  if (decimalPart !== undefined) {
    formattedNumber += currency.decimalSeparator + decimalPart;
  }

  return formattedNumber;
};

const convert = async (price: Price, toCurrencyId: CurrencyId): Promise<Price> => {
  if (!isFullPrice(price)) {
    return price;
  }

  const currencyFrom = await getById(price.currencyId);
  const currencyTo = await getById(toCurrencyId);

  const decimalPrecision = currencyTo.decimalPrecisionWhenCurrencySwitch;
  const ratio = currencyFrom.ratioThousands[currencyTo.id] / 1000;
  const priceConverted = price.price * ratio;
  const withoutDecimals = Number(priceConverted.toFixed(decimalPrecision));

  return {
    price: withoutDecimals,
    currencyId: currencyTo.id,
  };
};

export const Currencies = {
  getAll,
  getById,
  convert,
  formatAmount,
  CurrencyId,
  FallbackCurrencyId,
};
