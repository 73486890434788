export default {
  landingPages: {
    heroCta: "Vytvor si zbierku",
    sliderHeadingLine1: "Zbierka",
    sliderHeadingLine2: "v troch krokoch",
    watches: {
      heading: "TVOJE RADOSTI&nbsp;NA<br>JEDNOM MIESTE",
      text: "Si nadšenec do hodiniek? Máš doma tri plné hodinkovnice alebo len pár špeciálnych kúskov? V&nbsp;každom prípade&nbsp;–&nbsp;vitaj medzi nami. Objav nový svet chronobláznov: Vytvor a dopĺňaj svoju unikátnu zbierku, čítaj novinky a vyskúšaj všetky funkcie, ktoré sme pre teba pripravili.",
      textSeo: "<p>Hovoria vám niečo značky hodiniek Rolex, Breitling, Cartier alebo Tudor? V tom prípade sú zberateľské hodinky iste vašou vášňou. Užívate si precízne ručne vyrobené hodinky s mnohými komplikáciami, mechanickým strojčekom a exkluzívnym vzhľadom? Krásne je, že má každý zberateľ trochu iný vkus. Niekto radšej potápačské hodinky, niekto pilotky a iný digitálky v štýle G shock. Ať je to akokoľvek, všetci hodinoví nadšenci majú spoločného jedno: Lásku k dobrej hodinárine.</p>",
      slides: {
        slide1: {
          heading: "Maj prehľad<br />o&nbsp;svojich hodinkách",
          text: "Zaujímaš sa o&nbsp;hodinky alebo len máš rád poriadok vo svojich veciach? Pridávaj si hodinky aj iné predmety do svojho portfólia a maj všetky informácie neustále po ruke.",
          cta: "Vytvor si zbierku",
        },
        slide2: {
          heading: "Máš rád čísla? <br />Sleduj ich.",
          text: "Využívaj grafy, prehľadné tabuľky s parametrami alebo bilancie hodnoty tvojej zbierky. Sám si urči hodnotu svojej zbierky hodiniek.",
          cta: "Vytvor si zbierku",
        },
        slide3: {
          heading: "Buď s&nbsp;nami vždy <br />v&nbsp;obraze",
          text: "Prinášame ti informácie zo sveta hodiniek, vrátane najčerstvejších noviniek. Na jednom mieste tak nájdeš všetko, čo ťa o tvojej radosti zaujíma.",
          cta: "Vytvor si zbierku",
        },
      },
    },
    faq: {
      heading: "štyri najčastejšie otázky:",
      isItFree: {
        question: "Je Moja zbierka zadarmo?",
        answer: "<p>Ano, svoju zbierku so všetkými funkcionalitami máš k dispozícii plne zadarmo.</p>",
      },
      howManyCategories: {
        question: "Koľko zberateľských kategórií pokrývate?",
        answer: "<p>Hlavných kategórií je niečo cez 30 a nové stále pribúdajú. I tak je zbierka postavená na tom, že si môžeš pridať čokoľvek, čo ti robí radosť alebo o čom by si rád mal prehľad. Pokiaľ ide o naše kategórie, nájdeš u nich presné špecifikácie, navyše k nim postupne dopĺňame kompletné databázy.</p>",
      },
      profileVisibility: {
        question: "Kto vidí môj profil a&nbsp;predmety?",
        answer: "<p>Zbierka je chránená tak, aby nikto okrem teba nevidel, čo si do zbierky pridávaš. Nemusíš sa tak báť o svoje údaje.</p>",
      },
      extraFeatures: {
        question: "Vie zbierka ešte niečo?",
        answer: "<p>Zbierka má plno funkcionalít. Okrem samotného pridávania predmetu a vytvárania zbierok môžeš sledovať aktuálne grafy vývoja cien predmetov, sledovať novinky a odporúčania alebo čítať magazín. Pri tom však nekončíme. Postupne rozširujeme zbierku o ďalšie a ďalšie funkcionality.</p>",
      },
      cta: "Vytvor si zbierku",
    },
  },
};
