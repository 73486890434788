export default {
  publicItemsFilter: {
    fulltextPlaceholder: "Názov / kód predmetu",
    reset: "Obnoviť všetko",
    onlyForSale: "Len na predaj",
    categories: {
      placeholder: "Všetky kategórie",
      all: "@:components.publicItemsFilter.categories.placeholder",
      favourite: "Obľúbené kategórie",
    },
    price: {
      label: "Cena",
      from: "od",
      to: "do",
      includeNegotiablePrice: "Cena dohodou",
    },
  },
};
