<template>
  <ElCheckbox v-model="isChecked" :label="label" v-bind="$attrs" class="colectio-checkbox" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { ElCheckbox } from "element-plus";

const props = defineProps({
  label: String,
  modelValue: Boolean,
});

const isChecked = ref(props.modelValue);

// Emit change
const emit = defineEmits<{
  (e: "update:modelValue", payload: boolean): void;
  (e: "change", payload: boolean): void;
}>();

watch(isChecked, (value) => {
  emit("update:modelValue", value);
  emit("change", value);
});

watch(
  () => props.modelValue,
  (newValue) => (isChecked.value = newValue)
);
</script>

<style lang="scss" scoped>
.colectio-checkbox {
  --el-checkbox-input-border: var(--controlBorder);
  --el-checkbox-border-radius: 50%;
  --el-checkbox-font-size: var(--font-size);
  --el-checkbox-font-weight: 400;
  --el-checkbox-input-border-color-hover: var(--controlBorderColor);
  --el-checkbox-checked-bg-color: var(--greenPrimary);
  --el-checkbox-input-height: 18px;
  --el-checkbox-input-width: 18px;
  --el-checkbox-text-color: var(--gray800);
  --el-checkbox-checked-text-color: var(--gray800);

  // Check icon
  &:deep(.el-checkbox__input .el-checkbox__inner:after) {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border-width: 2px;
  }

  // Label
  &:deep(.el-checkbox__label) {
    white-space: normal;
    line-height: 1.2;
  }

  &:hover {
    --el-checkbox-text-color: var(--gray900);
    --el-checkbox-checked-text-color: var(--gray900);

    // Check icon
    &:deep(.el-checkbox__input .el-checkbox__inner:after) {
      border-color: var(--controlBorderColor);
      transform: rotate(45deg) scaleY(1);
    }
  }

  &.is-checked {
    --el-checkbox-input-border: 1px solid var(--greenPrimary);
    // Main checkbox part on hover (green circle)
    &:hover {
      --el-checkbox-checked-input-border-color: var(--green500);
      &:deep(.el-checkbox__input .el-checkbox__inner) {
        background-color: var(--green500);
      }
      // Icon on hover (when checked)
      &:deep(.el-checkbox__input .el-checkbox__inner:after) {
        border-color: var(--white);
      }
    }
  }
}
</style>
