export default {
  addItem: {
    heading: "Přidej si předmět",
    favouriteCategories: "Oblíbené kategorie",
    loadingCategories: "Načítám kategorie...",
    itemName: "Název předmětu / ID",
    itemVisibilityLabel: "Veřejný předmět",
    itemVisibilityTooltip: "@:views.itemDetail.params.isItemPublicTooltip",
    createItemButton: "Přidat do sbírky",
    creatingItem: "Ukládám předmět...",
    created: "Předmět vytvořen.",
    creationFailed: "Předmět se nepodařilo vytvořit.",
    errors: {
      noCategory: "Vyber kategorii.",
      noItemName: "Zadej název nebo ID předmětu.",
    },
  },
};
