export default {
  form: {
    phonePatternExplanation: "Vyplň telefónne číslo vo formáte +42123456789.",
    wrongEmailFormat: "Vyplň existujúcu e-mailovú adresu.",
    wrongUrlFormat: "Vyplň platnú URL.",
    wrongUrlDomain: "Zadaná URL nemá požadovanú doménu.",
    wrongPasswordFormat: "Heslo musí obsahovať malé písmeno, veľké písmeno, číslicu a aspoň { minLength } znakov.",
    required: "Povinné",
    select: {
      placeholder: "Vyber zo zoznamu",
      searchablePlaceholder: "Vyhľadať",
    },
  },
};
