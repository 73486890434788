export default {
  magazine: {
    breadcrumb: "Magazín",
    titleNoCategory: "Magazín",
    titleCategory: "{ categoryName } magazín",
    heading: "Magazín",
    shareBtn: "Zdieľať",
    showMoreBtn: "Zobraziť viac",
    readLength: "min čítania",
    readMore: "čítať viac",
    filterPlaceholder: "Kategória",
    relatedHeading: "Mohlo by ťa zaujímať",
    relatedSubheading: "Magazín",
    relatedMore: "Viac článkov",
    banner: {
      heading1: "Tvoja zbierka",
      heading2: "na jednom mieste",
      li1: "Sleduj vývoj hodnoty svojej zbierky",
      li2: "Využívaj aktuálne databázy",
      li3: "Získaj zaujímavé tipy pre zberateľov",
      cta: "Vytvoriť zbierku",
      imgAlt: "Tvoja zbierka na jednom mieste",
    },
    widget: {
      error: "Predmet sa nepodarilo načítať.",
      currency: "Kč",
      cta: "Detail predmetu",
    },
    survey: {
      loading: "Načítavam anketu...",
      votesCount: "hlasov | hlas | hlasy | hlasov",
    },
  },
};
