export default {
  profileInitialization: {
    nickname: {
      label: "Zvoľ si prezývku",
      placeholder: "Tvoja prezývka",
      help: "Prezývka, pod ktorou budeš fungovať v našom zberateľskom svete.",
      error: {
        taken: "Prezývku už niekto používa. Nemáš dvojníka? :) Skús prosím nejakú inú.",
        wrongFormat: "Chápeme, krátke prezývky sú cool, používatelia ťa ale chcú rozpoznať! Minimálny počet znakov je 3.",
      },
    },
    favouriteCategories: {
      label: "@:views.userSettings.favouriteCategories.title",
      placeholder: "@:views.userSettings.favouriteCategories.placeholder",
      help: "@:views.userSettings.favouriteCategories.help",
    },
    submit: "Dokončiť",
  },
};
