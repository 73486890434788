/**
 * Why marketingHelpers.ts?
 * Because sometimes we want to track same event into multiple tools.
 * You can remove marketingHelpers.ts when we will use single analytics tools (like umami for example).
 */

import { AccountType } from "./user/internalUserTypes";
import * as GoogleAnalytics from "@/services/googleAnalytics";
import * as FacebookPixel from "@/services/facebookPixel";
import * as Umami from "@/services/umami";

export function trackNewRegistrationEvent(userId: number, accountType: AccountType): void {
  const gaEvent = new GoogleAnalytics.AccountVerifiedEvent(userId, accountType);
  GoogleAnalytics.trackEvent(gaEvent);

  const fbEvent = FacebookPixel.FacebookPixelEvent.CompleteRegistration;
  FacebookPixel.trackEvent(fbEvent);

  const umamiEvent: Umami.Event = { name: Umami.EventName.RegistrationDone };
  Umami.trackEvent(umamiEvent);
}

export function trackProfileShare(source: Umami.ShareProfileBoxSource): void {
  const umamiEvent: Umami.Event = {
    name: Umami.EventName.ShareProfileBox,
    params: { source },
  };
  Umami.trackEvent(umamiEvent);
}
