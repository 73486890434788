import { Events } from "@/types";
import { NETWORK_ERROR } from "./errorCodes";
import { logout } from "@/services/user";
import { Response401Exception } from "@/services/api2";
import { translate } from "@/services/translation";

function handleError(error) {
  if (error.response) {
    handleErrorResponse(error);
  } else if (error.request) {
    handleRequestError(error);
  } else {
    handleGeneralError(error);
  }
}

function handleErrorResponse(error) {
  if (error.response.status == 401) {
    logout(false);
    throw new Response401Exception();
  } else {
    const msg = error.message;
    window.eventBus.emit(Events.toastError, msg);
    Promise.reject(error.response);
  }
}

function handleRequestError(error) {
  Promise.reject(error.request);

  let msg = error.message;

  if (error.code == NETWORK_ERROR) {
    msg = translate("errors.internetConnectionLost");
  }

  window.eventBus.emit(Events.toastError, msg);
}

function handleGeneralError(error) {
  Promise.reject(error);
  window.eventBus.emit(Events.toastError, error.message);
}

export { handleError };
