import { createDashboardLink, router } from "@/router";
import { requireUser } from "@/services/user/userAuthentication";

export function redirectAfterLogin() {
  const route = router.currentRoute;

  const urlRedirect = route.value.query.redirect_url;
  if (urlRedirect) {
    router.push(urlRedirect as string);
  } else {
    const dashboardRoute = createDashboardLink();
    router.push(dashboardRoute);
  }
}

export function isMyNick(nickname: string): boolean {
  try {
    const me = requireUser();
    const myNickname = me.data.nickname.toLowerCase();
    return myNickname === nickname.toLowerCase();
  } catch (e: unknown) {
    return false;
  }
}
