export default {
  publicHomepage: {
    hero: {
      textLine1: "Sbírej, prodávej",
      textLine2: "nebo vyměňuj",
      categories: {
        lego: "LEGO",
        watch: "hodinky",
        vinyls: "vinyly",
        art: "umění",
        alcohol: "alkohol",
        cards: "kartičky",
      },
      cta: "Vytvoř si sbírku a prodávej",
    },
    items: {
      heading: "Předměty sběratelů",
      subHeadingText: "Sbírej, prodávej, vyměňuj. To vše je možné v naší nově spuštěné sběratelské sekci.",
      cta: "Více předmětů",
      errorMsg: "Předměty se nepodařilo načíst.",
      errorRetryBtn: "Zkus znovu",
    },
    collectors: {
      heading: "Sběratelé",
      cta: "Více sběratelů",
    },
    magazine: {
      heading: "Magazín",
      moreBtn: "Více článků",
    },
  },
};
