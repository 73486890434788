export enum BuildMode {
  development = "development",
  beta = "beta",
  production = "production",
}

export enum SortDirection {
  asc = "ASC",
  desc = "DESC",
}

export interface Sort {
  by: string;
  direction: SortDirection;
}
