/**
 * Not established = Most likely user has no nickname filled.
 */
export class UserNotEstablishedException extends Error {}

export class TokenNotValidException extends Error {}

export class ProfileDataSavingException extends Error {}

export class AuthenticationException extends Error {}

export class InvalidPasswordValueException extends Error {}
