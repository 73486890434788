export type Price =
  | {
      currencyId: number;
      price: number;
    }
  | {
      currencyId: null;
      price: typeof NegotiablePriceValue;
    }
  | typeof EmptyPriceValue
  | typeof NotForSaleValue;

export const NegotiablePriceValue = 0;
export const NotForSaleValue = null;
export const EmptyPriceValue = null;

export const isNegotiablePrice = (price: Price): boolean => price?.currencyId === null && price.price === NegotiablePriceValue;
export const isEmptyPrice = (price: Price): boolean => price === EmptyPriceValue;
export const isNotForSalePrice = (price: Price): boolean => price === NotForSaleValue;
export const isFullPrice = (price: Price) => price !== NotForSaleValue && price !== EmptyPriceValue && price.currencyId !== null;

export const createNegotiablePrice = (): Price => ({ price: NegotiablePriceValue, currencyId: null });
export const createNotForSalePrice = (): Price => NotForSaleValue;
export const createEmptyPrice = (): Price => EmptyPriceValue;
export const createPrice = (amount: number | null, currencyId: number | null): Price => {
  if (amount === EmptyPriceValue) {
    return EmptyPriceValue;
  } else if (currencyId === null) {
    return { price: NegotiablePriceValue, currencyId: null };
  } else {
    return { price: amount, currencyId };
  }
};
