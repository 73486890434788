export default {
  gallery: {
    close: "Zavřít",
    previous: "Předchozí",
    next: "Další",
    menu: "Možnosti",
    imageAdd: "Přidat fotografii",
    imageDelete: "Smazat fotografii",
    imageSetAsCover: "Nastavit jako cover",
    coverTag: "cover",
    deleteConfirmation: {
      title: "Odstranění obrázku",
      msg: "Opravdu chcete odstranit tento obrázek?",
    },
    noImage: {
      text: "Tvůj předmět čeká na svůj moment slávy! Přidej si první fotku a pochlub se ostatním.",
      cta: "@:components.gallery.imageAdd",
    },
  },
};
