import { translate } from "@/services/translation";
import { Events } from "@/types";

const PrepositionsSearchedElements = "h1, h2, h3, h4, p";

export function escapeForRegex(str: string): string {
  const REGEXP_SPECIAL_CHAR = /[!#$%^&*)(+=.<>{}[\]:;'"|~`_-]/g;
  return str.replace(REGEXP_SPECIAL_CHAR, "\\$&");
}

export async function wrapPrepositions(container?: HTMLElement, searchFor: string = PrepositionsSearchedElements): Promise<void> {
  if (!container) {
    container = document.body;
  }

  container.querySelectorAll(searchFor).forEach((element) => {
    wrapPrepositionsInTextNodes(element);
  });
}

function wrapPrepositionsInTextNodes(node: ChildNode): void {
  if (node.nodeName === "#text") {
    const value = node.nodeValue;

    if (value) {
      node.nodeValue = value.replace(/\b([a-z0-9])(\s+)/gim, "$1\xa0");
    }
  } else {
    node.childNodes.forEach((childNode) => {
      wrapPrepositionsInTextNodes(childNode);
    });
  }
}

export function truncate(str: string, maxLength: number): string {
  if (str.length > maxLength) {
    return str.slice(0, maxLength) + "…";
  } else {
    return str;
  }
}

export function createTelHref(tel: string): string {
  return "tel:" + tel.replaceAll("+", "00").replace(/\D+/g, "");
}

export function copyToClipboard(str: string, toastMessage = translate("copiedToClipboard")): void {
  navigator.clipboard.writeText(str);
  window.eventBus.emit(Events.toastSuccess, toastMessage);
}
