import { Breadcrumb } from "@/composables";
import { defineStore } from "pinia";

type BreadcrumbsStoreState = {
  breadcrumbs: Breadcrumb[];
};

export const useBreadcrumbsStore = defineStore("breadcrumbs", {
  state: () =>
    ({
      breadcrumbs: [],
    } as BreadcrumbsStoreState),

  actions: {
    add(breadcrumb: Breadcrumb) {
      this.breadcrumbs.push(breadcrumb);
    },
    set(breadcrumbs: Breadcrumb[]) {
      this.breadcrumbs = breadcrumbs;
    },
    clear() {
      this.breadcrumbs = [];
    },
    get(): Breadcrumb[] {
      return this.breadcrumbs;
    },
  },
});
