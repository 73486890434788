export default {
  cookieConsent: {
    modal: {
      title: "Máme radi cookies 🍪",
      description: "Aby sme ti mohli ponúknuť personalizovaný obsah a uložiť tvoje preferencie, používame cookies. Dáš nám zelenú?",
      acceptAllBtn: "Prijať všetko",
      acceptNecessaryBtn: "Prijať nevyhnutné",
      showPreferencesBtn: "Nastavenie cookies",
      closeIconLabel: "Odmietnuť všetko",
    },
    preferencesModal: {
      title: "Nastavenie cookies",
      acceptAllBtn: "Prijať všetko",
      acceptNecessaryBtn: "Odmietnuť všetko",
      savePreferencesBtn: "Uložiť nastavenia",
      sections: {
        description: {
          title: "Čo sú cookies?",
          description: "Súbory cookies a ďalšie technológie nám pomáhajú zlepšovať naše služby a pomáhajú nám analyzovať výkon webu. V nastavení si môžete vybrať, ktoré cookies môžeme používať. Svoj súhlas môžete kedykoľvek odvolať.",
        },
        essentials: {
          title: "Nezbytné cookies pre správne fungovanie nášho webu.",
          description: "Tieto cookies sú nevyhnutné pre správnu funkciu webu – zabezpečujú správne zobrazenie, odosielanie formulárov, vkladanie tovaru do košíka a podobne. Technické cookies nie je možné vypnúť.",
        },
        analytics: {
          title: "Sledovanie návštevnosti a správania na webe nám pomáha zlepšovať naše služby.",
          description: "Štatistické cookies používame na vyhodnocovanie výkonu nášho webu a reklamných kampaní. Pomocou cookies určujeme počet návštev, zdroje návštevnosti a správanie zákazníkov na našom webe – napr. informácie o tom, čo na webe hľadáte a ktoré informácie sú pre vás najdôležitejšie. Na základe týchto dát môžeme vykonávať optimalizáciu webu a zlepšovať naše služby.",
        },
        ads: {
          title: "Cookies pre cielenie a reklamu",
          description: "Tieto cookies sa používajú na zobrazovanie reklamy, ktorá vás pravdepodobne bude zaujímať na základe vašich návykov pri prehliadaní. Tieto cookies, ktoré požadujeme my alebo poskytovatelia reklamy, môžu kombinovať informácie zhromaždené z našich webových stránok s ďalšími informáciami, ktoré nezávisle zhromaždili z iných webových stránok, týkajúcich sa činností vášho internetového prehliadača v rámci ich reklamnej siete.",
        },
        personalization: {
          title: "Personalizácia obsahu",
          description: "Tieto cookies nám umožnia zobraziť vám obsah podľa údajov, ktoré sú o vás dostupné, aby sme čo najlepšie vyhoveli vašim potrebám. Ide najmä o to, aký obsah ste si zobrazili alebo na akom zariadení prichádzate na naše stránky.",
        },
      },
    },
  },
};
