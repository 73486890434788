export function getCookie(cname: string): string | null {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return null;
}

export function setCookie(name: string, value: string, expiration?: string | number, path?: string): void {
  let str = name + "=" + value + ";";

  if (expiration) {
    str += " expires=" + expiration.toString() + ";";
  }

  if (path) {
    str += " path=" + path;
  }

  document.cookie = str;
}

export function deleteCookie(name: string, path = "/"): void {
  if (!getCookie(name)) {
    return;
  }

  const expiration = Date.now() - 3600 * 24 * 30 * 12; // circa one year into the past
  setCookie(name, "", expiration, path);
}
