export default {
  publicCollectionItemCard: {
    collector: "Sběratel",
    offeringFor: "Nabízím za",
    notForSale: "neni k prodeji",
    amount: "Množství",
    amountUnit: "ks",
    publishDate: "Zvěřejněno",
  },
};
