export default {
  errors: {
    unknown: "Ops. Operace se nezdařila. Pokud problémy přetrvávají, kontaktujte nás prosím.",
    error400: "Chybný požadavek (status 400).",
    error403: "Nemáš oprávnění provést tuto operaci (status 403).",
    general: "Operaci se nepodařilo provést. Chybu jsme zaznamenali a pokusíme se jí co nejdříve opravit. Zkus to prosím znovu brzy znovu.",
    internetConnectionLost: "Nepodařilo se načíst data. Jste připojení k internetu?",
  },
  close: "Zavřít",
  doNotShowNextTime: "Příště již nezobrazovat",
  yes: "Ano",
  no: "Zrušit",
  chart: {
    period: {
      month: "1 měsíc",
      halfYear: "6 měsíců",
      year: "1 rok",
      full: "Maximální",
    },
  },
  date: {
    today: "dnes",
    yesterday: "včera",
    beforeXDays: "před { days } dny",
  },
  pagination: {
    outOf: "z",
    pages: "str.",
  },
  negotiablePrice: "Dohodou",
  notForSale: "Není k prodeji",
  piecesUnit: "ks",
  sort: {
    default: "Řazení",
    dateAsc: "Nejstarší",
    dateDesc: "Nejnovější",
    priceAsc: "Nejlevnější",
    priceDesc: "Nejdražší",
    alphabetAsc: "Název A-Z",
    alphabetDesc: "Název Z-A",
    relevance: "Relevance",
    likes: "Nejoblíbenější",
  },
  versionBar: {
    text: "<strong>Nová verze</strong> webu je k dispozici.",
    btnDesktop: "Aktualizovat stránku",
    btnMobile: "Aktualizovat",
  },
  categorySelectPlacehoder: "Vyber kategorii",
  copiedToClipboard: "Zkopírováno do schránky.",
};
