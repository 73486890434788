export default {
  books: {
    heading: "Príručky na stiahnutie",
    textSubheading: "Postupne pre vás chystáme užitočné príručky, ktoré sa zameriavajú na investície do jednotlivých oblastí. Ak ste začiatočník v zbieraní alebo sa len chcete dozvedieť užitočné informácie, nájsť kvalitné zdroje, príručky sú pre vás zdarma na stiahnutie. Na nič nečakajte. :)",
    download: "Stiahnuť",
    books: {
      art: "Ako začať s investovaním do umenia",
      shoes: "Ako začať s investovaním do tenisiek",
      lego: "Ako začať s investovaním do LEGA",
      watch: "Ako začať s investovaním do hodiniek",
      alcohol: "Ako začať s investovaním do alkoholu",
    },
  },
};
