export enum Layout {
  app,
  appDialog,
  public,
  lockingState,
}

export interface LayoutSettings {
  name: Layout;

  // dialog layout settings:
  width?: string;
  height?: string;
  class?: string;
}
