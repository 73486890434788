export default {
  contacts: {
    heading: "Kontakty",
    introText: "Chceš sa na niečo opýtať alebo nám napísať nejakú radostnú novinku? Píš, píš, tešíme sa.",
    headingForm: "Kontaktný formulár",
    company: "Firemné údaje",
    address: "Sídlo:",
    court: "vedená na Krajskom súde v { city }, { itemNo }",
    form: {
      name: "Meno",
      email: "Tvoj e-mail",
      message: "Tvoja správa",
      messagePlaceholder: "Dobrý deň, môžem sa opýtať na….",
      submit: "Odoslať správu",
      thankYou: "Ďakujeme za tvoj dopyt, odpovieme čo najskôr!",
      back: "Späť na formulár",
      invalidName: "Vyplň svoje meno.",
      invalidMessage: "S čím ti môžeme pomôcť?",
    },
  },
};
