import { useI18n } from "vue-i18n";

/**
 * A custom hook for handling translations using i18n.
 *
 * @param {string} [localesNamespace=""] - An optional namespace to prefix translation keys.
 *
 * The `translate` function formats and retrieves localized strings based on the provided message key and arguments.
 * If the message does not start with "@", the function prefixes it with the specified `localesNamespace`.
 */
export function useTranslation(localesNamespace = "") {
  const { t } = useI18n();

  const translate = (message: string, args: Record<string, unknown> = {}): string => {
    let path = message;
    if (message.startsWith("@:")) {
      path = message.substring(2);
    } else if (localesNamespace) {
      path = localesNamespace + "." + message;
    }

    return t(path, args);
  };

  return {
    translate,
  };
}
