export default {
  collectors: {
    heading: "Zberatelia",
    text: "Prezri si portfóliá ostatných zberateľov a objav, kto má rovnakú vášeň ako ty.",
    filter: {
      showBtn: "Nič nenájdené | Zobraziť {count} zberateľov | Zobraziť {count} zberateľa | Zobraziť {count} zberateľov",
      showBtnLoading: "Hľadám zberateľov…",
      sort: {
        itemCountDesc: "Najviac predmetov",
      },
    },
    grid: {
      emptyMsg: "Bohužiaľ, tvojmu filtru nevyhovuje žiadny zberateľ. Skús vyhľadávanie upraviť.",
      errorMsg: "Ops. Chyba vyhľadávania.",
      errorMsgBtn: "Obnoviť stránku",
    },
    card: {
      itemsSumCollection: "predmetov v zbierke | predmet v zbierke | predmety v zbierke | predmetov v zbierke",
      itemsSumCategory: "Žiadny predmet | predmet | predmety | predmetov",
    },
  },
};
