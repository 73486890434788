export default {
  ebay: {
    heading: "Naceňovač",
    introText: "Neznáš cenu svých předmětů, nebo si jí nejsi jistý? Vytvořili jsme pro tebe naceňovač. Jednoduše si vyjeď, za kolik podobné předměty nabízejí ostatní a zjisti, jakou mají ve světě přibližnou cenu. (eBay)",
    tips: {
      heading: "Tipy pro vyhledávání",
      point1: "Chcete-li vyhledat více variant stejného výrazu, uzavřete je do hranatých závorek a oddělte je čárkou (například:  [PSA, BGS])",
      point2: "Pro vyloučení výrazů z vyhledávání použijte znaménko mínus „-“ (například: LEGO® Star Wars -mandalorian -cruiser)",
      point3: "Použijte & pro shodu pouze s výrazy daného vzoru. Charizard PSA&10",
    },
    form: {
      submit: "Vyhledat ceny",
      itemType: {
        sold: "Prodané předměty",
        active: "Nabízené předměty",
      },
      queryPlaceholder: "Hledej…",
      category: {
        all: "Všechny kategorie",
        sportsCards: "Sportovní karty",
        baseball: "Baseball",
        football: "Americký fotbal",
        soccer: "Fotbal",
        basketball: "Basketbal",
        iceHockey: "Hokej",
        golf: "Golf",
        tennis: "Tenis",
        mma: "MMA",
        box: "Box",
        racing: "Závodění",
        cardGames: "Trading card games",
        pokemon: "Pokémon",
        mtg: "Magic: The Gathering",
        yugioh: "Yu-Gi-Oh!",
        lego: "LEGO®",
        vinyls: "Vinylové desky",
        watches: "Hodinky",
        shoes: "Tenisky",
        shoesMen: "Tenisky pánské",
        shoesWomen: "Tenisky dámské",
        stamps: "Známky",
        coins: "Mince",
        comics: "Komiksy",
        postcards: "Pohlednice",
        funkopop: "Funko-pop",
        weapons: "Historické zbraně",
        games: "Hry",
        arcadeGames: "Arkády & pinball",
        videoGames: "Konzole",
      },
      sort: {
        highestPrice: "Řadit od nejvyšší ceny",
        lowestPrice: "Řadit od nejnižší ceny",
        mostRecent: "Řadit od nejnovějšího",
      },
      error: {
        emptyQuery: "Vyplň hledaný výraz.",
      },
    },
    list: {
      heading: "Výsledky vyhledávání",
      name: "Název",
      priceCurrent: "Aktuální cena",
      priceFinal: "Prodáno za",
      bidsCount: "Aukce (počet příhozů)",
      dateStart: "Datum vystavení",
      dateEnd: "Datum prodeje",
      emptyMsg: "Nebyl nalezen žádný předmět. Zkus upravit parametry vyhledávání.",
    },
  },
};
