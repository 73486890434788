import { addTokenToUrl, API } from "@/services/api2";
import { UserData, UserDataPublic, UserFlag } from "@/types/user";
import { InvalidPasswordValueException, ProfileDataSavingException, UserNotEstablishedException } from "./userExceptions";
import { AxiosError, AxiosResponse } from "axios";
import { User, requireUser } from "@/services/user";
import { validatePassword } from "../validators";
import { translate } from "@/services/translation";
import { Currencies } from "@/services/currencies";
import { UpdateUserSettingsData } from "./internalUserTypes";
import { Categories } from "../repositories/collection/categories";
import { CategoryId } from "@/types";

const BaseUrl = import.meta.env.VITE_API_COLLECTOR_URL;
const CollectorApiUrl = BaseUrl + "user/collector";
const UserApiUrl = BaseUrl + "user";
const FlagsApiUrl = BaseUrl + "user/collector-flag";
const AvatarApiUrl = BaseUrl + "user/avatar";
const OldApiUrl = "accounts/";

const WrongOldPasswordStatus = "wrong-old-password"; // in response

type ApiResponse_UserData = {
  userId: number;
  nicknameDaysTillNew: number;
  flags: UserFlag[];
  favoriteCategoryIds: number[];
} & UserDataPublic;

type ApiResponse_AvatarUpload = {
  newProfilePhotoUrl: string;
};

interface CreateCollectorData {
  nickname: string;
  categoryId: CategoryId[];
}

export async function hidePrices(): Promise<AxiosResponse> {
  const url = addTokenToUrl(OldApiUrl + "hide-prices");
  return API.post(url);
}

export async function showPrices(): Promise<AxiosResponse> {
  const url = addTokenToUrl(OldApiUrl + "show-prices");
  return API.post(url);
}

export async function setNewPassword(oldPassword: string, newPassword: string, newPasswordAgain: string): Promise<AxiosResponse> {
  validatePassword(newPassword);

  if (oldPassword === "") {
    throw new InvalidPasswordValueException(translate("views.userSettings.errors.emptyCurrentPassword"));
  }

  if (newPassword !== newPasswordAgain) {
    throw new InvalidPasswordValueException(translate("views.userSettings.errors.passwordsMismatch"));
  }

  const url = UserApiUrl + "/password";
  const data = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };

  return API.post(url, {}, data).catch((error) => {
    if (error.status === WrongOldPasswordStatus) {
      const msg = translate("views.userSettings.errors.currentPasswordIncorrect");
      throw new InvalidPasswordValueException(msg);
    } else {
      throw error;
    }
  });
}

/** User data = internal account settings & account info. */
export async function getUserData(): Promise<UserData> {
  return API.get<ApiResponse_UserData>(CollectorApiUrl)
    .then((response) => {
      return createUserDataObject(response.data);
    })
    .catch((error) => {
      const status = error.response?.status;
      const UserNotEstablishedStatuses = [400, 404];
      if (UserNotEstablishedStatuses.includes(status)) {
        throw new UserNotEstablishedException();
      } else {
        throw error;
      }
    });
}

export async function createCollector(data: CreateCollectorData): Promise<User> {
  return API.post<ApiResponse_UserData>(CollectorApiUrl, {}, data)
    .then((response) => {
      return createUserDataObject(response.data).then((userData) => {
        const user = requireUser();
        user.data = userData;
        return user;
      });
    })
    .catch((error: AxiosError) => {
      const status = error.response?.status;
      if (status === 400) {
        const msg = translate("views.userSettings.errors.badRequest");
        throw new ProfileDataSavingException(msg);
      } else if (status === 409) {
        const msg = translate("views.userSettings.errors.nicknameTaken");
        throw new ProfileDataSavingException(msg);
      }

      throw error;
    });
}

export async function updateUserSettings(data: UpdateUserSettingsData): Promise<User> {
  return API.put<ApiResponse_UserData>(CollectorApiUrl, {}, data)
    .then((response) => {
      return createUserDataObject(response.data).then((userData) => {
        const user = requireUser();
        user.data = userData;
        return user;
      });
    })
    .catch((error: AxiosError) => {
      const status = error.response?.status;
      if (status === 400) {
        const msg = translate("views.userSettings.errors.badRequest");
        throw new ProfileDataSavingException(msg);
      } else if (status === 404) {
        const msg = translate("views.userSettings.errors.notFound");
        throw new ProfileDataSavingException(msg);
      } else if (status === 409) {
        const data = error.response?.data as { error: string };
        const type = data.error;
        let msg = "Unknown error.";
        if (type && type === "nickname_taken") {
          msg = translate("views.userSettings.errors.nicknameTaken");
        } else if (type && type === "nickname_too_early_change") {
          msg = translate("views.userSettings.errors.nicknameTooEarlyChange");
        }

        throw new ProfileDataSavingException(msg);
      }

      throw error;
    });
}

export async function enableUserFlag(name: UserFlag): Promise<AxiosResponse> {
  const data = { name };
  return API.post(FlagsApiUrl, {}, data);
}

export async function disableUserFlag(name: UserFlag): Promise<AxiosResponse> {
  const data = { name };
  return API.delete(FlagsApiUrl, {}, data);
}

export async function updateFavouriteCategories(categoryId: CategoryId[]): Promise<AxiosResponse> {
  const data = { categoryId };
  const url = UserApiUrl + "/collector-favorite-category";
  return API.post(url, {}, data);
}

/**
 * @returns {Promise<string>} Url of a new avatar image.
 */
export async function saveAvatar(image: File): Promise<string> {
  const data = new FormData();
  data.append("image", image);
  return API.post<ApiResponse_AvatarUpload>(AvatarApiUrl, {}, data).then((response) => {
    return response.data.newProfilePhotoUrl;
  });
}

const createUserDataObject = async (data: ApiResponse_UserData): Promise<UserData> => {
  // Always use CZK. For now. See: https://app.asana.com/0/1205266520331763/1207204526860554/f
  const displayCurrency = await Currencies.getById(Currencies.FallbackCurrencyId);
  const publicItemsDefaultCurrency = await Currencies.getById(Currencies.FallbackCurrencyId);
  const promises = data.favoriteCategoryIds.map(async (categoryId) => await Categories.getById(categoryId));
  const favouriteCategories = await Promise.all(promises);

  return {
    displayCurrency,
    publicItemsDefaultCurrency,
    nickname: data.nickname,
    nicknameDaysTillNew: data.nicknameDaysTillNew,
    publicFullName: data.publicFullName,
    publicEmail: data.publicEmail,
    publicPhone: data.publicPhone,
    publicUrlFacebook: data.publicUrlFacebook,
    publicUrlInstagram: data.publicUrlInstagram,
    publicUrlTikTok: data.publicUrlTikTok,
    publicUrlPinterest: data.publicUrlPinterest,
    publicUrlWeb: data.publicUrlWeb,
    publicBioDescription: data.publicBioDescription,
    flags: new Set(data.flags),
    favouriteCategories,
    isChatEmailNotificationEnabled: data.isChatEmailNotificationEnabled,
  };
};
