import { Website } from "@/config";

const updateLinkCanonical = (url: string) => {
  const linkCanonical = document.querySelector('head link[rel="canonical"]');
  if (linkCanonical) {
    linkCanonical.setAttribute("href", url);
  } else {
    const newLinkCanonical = document.createElement("link");
    newLinkCanonical.rel = "canonical";
    newLinkCanonical.href = url;
    document.head.appendChild(newLinkCanonical);
  }
};

const setTitle = (title?: string): void => {
  if (title) {
    document.title = title + " | " + Website.name;
  } else {
    document.title = Website.name;
  }
};

export const MetaTags = {
  updateLinkCanonical,
  setTitle,
};
