export default {
  catalogue: {
    breadcrumbs: "Databáze",
    intro: {
      heading: "Databáze",
      text: "Chceš najít informace o určitém předmětu? Máme tu pro tebe ucelené databáze, které pravidelně aktualizujeme. Můžeš si tak dohledat přes filtr nebo vyhledávač informace o konkrétním předmětu i s jeho parametry, přímo z databází předměty přidávat do své sbírky, a to i se všemi údaji.",
      loading: "Načítám kategorie…",
      workInProgress: "připravujeme",
    },
    category: {
      title: "Databáze: { categoryName }",
      heading: "Databáze: { categoryName }",
      headingResults: "Výsledek hledání",
    },
    itemDetail: {
      addToCollection: "Přidat do sbírky",
    },
    itemBox: {
      id: "ID",
      price: "Cena",
    },
    filter: {
      searchButton: "Vyhledat",
      fulltextPlaceholder: "hledat...",
      fulltextTooShort: "Vyplňte alespoň { minLength } znaky.",
      submit: "Vyhledat",
      boardGames: {
        name: "Název",
        developers: "Autoři",
        languages: "Jazyk",
      },
      vinyls: {
        name: "Název / Cat / Kód",
        interpret: "Interpret",
        year: "Rok vydání",
        country: "Země",
        format: "Formát",
      },
      legoSets: {
        name: "Název předmětu / ID",
        set: "Set",
        theme: "Téma",
        year: "Rok vydání",
      },
      legoMinifigs: {
        fulltext: "Najít figurku",
      },
      watches: {
        name: "Najít hodinky",
        brand: "Značka",
      },
    },
    table: {
      empty: "Bohužel, tvému filtru neodpovídá žádná položka.",
      boardGames: {
        developer: "Autor",
        language: "Jazyk",
        year: "Rok vydání",
      },
      legoSets: {
        id: "ID",
        price: "Orientační<br />cena",
        year: "Rok vydání",
      },
      legoMinifigs: {
        id: "Číslo",
        price: "Orientační<br />cena",
        piecesCount: "Počet dílků",
      },
      watches: {
        reference: "Reference",
        brand: "Značka",
        model: "Model",
      },
      vinyls: {
        name: "Cat# / Kód",
        interpret: "Interpret",
        year: "Rok vydání",
      },
    },
  },
};
