<template>
  <picture>
    <source v-for="source in sourceImages" :key="source.url" :srcset="source.url" :type="'image/' + source.extension" :media="source.media" />
    <img v-if="fallbackImage" :src="fallbackImage" :alt="image.name" :title="image.name" v-bind="$attrs" />
  </picture>
</template>

<script setup lang="ts">
import { Image, ImageHtmlSettings, ImageSize, ImageUrls } from "@/services/images";
import { PropType, ref, watch } from "vue";

const FallbackExtension = "jpeg";

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  image: {
    type: Object as PropType<Image>,
    required: true,
  },
  settings: {
    type: [String, Object] as PropType<ImageSize | ImageHtmlSettings>,
    required: true,
  },
});

// <img ... />
const fallbackImage = ref();
// <source ... /> array
type Source = {
  extension: string;
  url: string;
  media?: string;
};
const sourceImages = ref<Source[]>();

// Helpers
const factorySourceImages = (images: ImageUrls, media?: string): Source[] => Object.entries(images).map(([extension, url]) => ({ extension, url, media }));
const mergeToSourceImages = (sources: Source[]) => {
  sourceImages.value = sourceImages.value ?? [];
  sourceImages.value?.push(...sources);
};
const parseSettingsSingleImage = (size: ImageSize): void => {
  const urlsByExtension = props.image.sizes[size];
  if (urlsByExtension) {
    const { [FallbackExtension]: fallbackUrl, ...sourceUrls } = urlsByExtension;
    fallbackImage.value = fallbackUrl;
    mergeToSourceImages(factorySourceImages(sourceUrls));
  } else {
    throw new Error("DEV: Requested non-existing image size.");
  }
};
const parseSettingsMultipleImages = (settings: ImageHtmlSettings): void => {
  settings.sources.forEach((source) => {
    const urlsByExtension = props.image.sizes[source.size];
    if (urlsByExtension) {
      const media = "(" + source.media + ")";
      const sourceImages = factorySourceImages(urlsByExtension, media);
      mergeToSourceImages(sourceImages);
    } else {
      throw new Error("DEV: Requested non-existing image size.");
    }
  });
  parseSettingsSingleImage(settings.fallbackSize);
};

// Setup fallbackImage and sourceImages variables
const parseSettings = () => {
  sourceImages.value = [];
  if (typeof props.settings === "string") {
    parseSettingsSingleImage(props.settings);
  } else {
    parseSettingsMultipleImages(props.settings);
  }
};
watch(() => [props.settings, props.image], parseSettings, { immediate: true });
</script>
