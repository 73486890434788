import { RouteLocationNormalized, createRouter, createWebHistory } from "vue-router";
import { TokenNotValidException, User, checkUserOnInitialPageLoad, getUser as getRunningAuthentication, wasSomeoneEverLoggedIn } from "@/services/user";
import { getEnumKeyByValue, isStringInEnum, wrapPrepositions } from "@/utilities";
import { PublicRouteName, publicRoutes } from "./publicRoutes";
import { AppRouteName, appRoutes, createProfileInitializationLink } from "./appRoutes";
import { ErrorRouteName, errorPagesRoutes } from "./errorRoutes";
import { createLoginLink, createRegistrationLink, LockingStateRouteName, lockingStateRoutes } from "./lockingStatesRoutes";
import { MetaTags } from "@/services/metaTags";
import { isPublic, updateBreadcrumbs, updatePageTitle } from "@/router/helpers";
import { CountryCode, CountryCode2LanguageCodeMap, LanguageCode, FallbackLanguageCode, setCountry, FallbackCountryCode } from "@/services/translation";
import { useCookieConsent } from "@/services/cookiesConsent";
import { useAppState } from "@/store/appState";
import { useUserStore } from "@/store";
import { Layout } from "@/types";

export const RouteName = { ...PublicRouteName, ...AppRouteName, ...LockingStateRouteName, ...ErrorRouteName };

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkExactActiveClass: "active",
  routes: [
    {
      path: "/",
      redirect: "/cz",
    },
    {
      path: "/facebook-sso-callback",
      components: { mainContent: () => import("@/views/FacebookSsoCallback.vue") },
      name: LockingStateRouteName.facebookSsoCallback,
      meta: {
        public: true,
        layout: { name: Layout.lockingState },
        allowNoCountryCode: true,
      },
    },
    {
      path: "/:countryCode(cz|sk)",
      children: [...publicRoutes, ...appRoutes, ...lockingStateRoutes, ...errorPagesRoutes],
    },
  ],
  scrollBehavior(to, from) {
    if (to.hash && document.getElementById(to.hash.slice(1))) {
      return { el: to.hash };
    }

    if (to.name === from.name && to.meta.preventScrollToTop === true) {
      return;
    }

    return { top: 0 };
  },
});

class LoginNeededException extends Error {}

const appState = useAppState();

router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
  const localization = getLocalization(to);
  if (localization) {
    setCountry(localization.country);
    appState.markLanguageDetected();
  } else {
    return next("/");
  }

  const isRoutePublic = isPublic(to);
  updatePageTitle(to);
  updateBreadcrumbs(to);

  try {
    const user = await getUser();

    const accountSetupIsNeeded = user && user.data === undefined && to.name !== AppRouteName.profileInitialization;
    if (accountSetupIsNeeded) {
      return next(createProfileInitializationLink());
    } else if (isRoutePublic) {
      const redirectWhenLoggedIn = to.meta.redirectWhenLoggedIn;
      if (redirectWhenLoggedIn && user) {
        const redirect = {
          ...redirectWhenLoggedIn,
          params: {
            ...redirectWhenLoggedIn.params,
            countryCode: useUserStore().getCountryCode(),
          },
        };
        return next(redirect);
      } else {
        return next();
      }
    } else {
      if (user) {
        return next();
      } else {
        throw new LoginNeededException();
      }
    }
  } catch (e: unknown) {
    const isTokenInvalid = e instanceof TokenNotValidException;
    if (isTokenInvalid && isRoutePublic) {
      return next();
    } else if (isTokenInvalid || e instanceof LoginNeededException) {
      if (wasSomeoneEverLoggedIn()) {
        const redirect = to.fullPath;
        return next(createLoginLink(redirect));
      } else {
        return next(createRegistrationLink());
      }
    } else {
      throw e;
    }
  }
});

router.afterEach((to: RouteLocationNormalized) => {
  wrapPrepositions();

  let url = window.location.href;
  if (to.fullPath === "/") {
    url += "cz";
  }
  MetaTags.updateLinkCanonical(url);

  const localization = getLocalization(to);
  const { cookieConsent } = useCookieConsent();
  cookieConsent.setLanguage(localization?.language ?? FallbackLanguageCode);
});

const getUser = async (): Promise<User | undefined> => {
  const user = await getRunningAuthentication();
  // no currently running authentication process or no token found
  if (user === undefined) {
    const newlyAuthenticatedUser = await checkUserOnInitialPageLoad(); // check if there is any token stored and possibly start authentication
    return newlyAuthenticatedUser === null ? undefined : newlyAuthenticatedUser;
  } else {
    return user;
  }
};

const getLocalization = (route: RouteLocationNormalized): { language: LanguageCode; country: CountryCode } | undefined => {
  const countryCodeString = route.params.countryCode as string;
  if (isStringInEnum(countryCodeString, CountryCode)) {
    const countryCode = getEnumKeyByValue(countryCodeString, CountryCode);
    const languageCode = CountryCode2LanguageCodeMap.get(countryCode);

    if (languageCode) {
      return { language: languageCode, country: countryCode };
    } else {
      throw new Error("DEV: Should not happen.");
    }
  } else if (route.meta.allowNoCountryCode === true) {
    return { language: FallbackLanguageCode, country: FallbackCountryCode };
  } else {
    return undefined;
  }
};

export { router };
