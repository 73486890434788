export function isNumber(event: KeyboardEvent): boolean {
  if (event.key.length !== 1) {
    return false;
  }

  const code = event.key.charCodeAt(0);

  return code >= 48 && code <= 57;
}

/** Accepts both lowercase and uppercase. */
export function isLetter(event: KeyboardEvent): boolean {
  if (event.key.length !== 1) {
    return false;
  }

  const code = event.key.charCodeAt(0);

  if (code >= 65 && code <= 90) {
    // upper case
    return true;
  } else if (code >= 97 && code <= 122) {
    // lower case
    return true;
  } else {
    return false;
  }
}

export function isArrowUp(event: KeyboardEvent): boolean {
  return event.key === "ArrowUp";
}

export function isArrowDown(event: KeyboardEvent): boolean {
  return event.key === "ArrowDown";
}

export function isArrowLeft(event: KeyboardEvent): boolean {
  return event.key === "ArrowLeft";
}

export function isArrowRight(event: KeyboardEvent): boolean {
  return event.key === "ArrowRight";
}

export function isArrow(event: KeyboardEvent): boolean {
  return isArrowDown(event) || isArrowUp(event) || isArrowRight(event) || isArrowLeft(event);
}

export function isEnter(event: KeyboardEvent): boolean {
  return event.key === "Enter";
}

export function isPunctuation(event: KeyboardEvent): boolean {
  const chars = [".", ",", "!", "?", ":", "'", '"', " "];

  return chars.includes(event.key);
}

export function isMinus(event: KeyboardEvent): boolean {
  return event.key === "-";
}

export function isDelete(event: KeyboardEvent): boolean {
  return event.key === "Delete";
}

export function isBackspace(event: KeyboardEvent): boolean {
  return event.key === "Backspace";
}

export function isEscape(event: KeyboardEvent): boolean {
  return event.key === "Escape";
}
