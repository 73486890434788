export function isStringInEnum(value: string, myEnum: Record<string, string>): boolean {
  for (const key in myEnum) {
    if (typeof myEnum[key] === "string" && myEnum[key] === value) {
      return true;
    }
  }
  return false;
}

export function getEnumKeyByValue<T extends { [key: string]: string }>(value: string, myEnum: T): T[keyof T] {
  const key = (Object.keys(myEnum) as Array<keyof T>).find((key) => myEnum[key] === value);
  if (key === undefined) {
    throw new Error("DEV: There is no key with value '" + value + "' in given enum.");
  }
  return myEnum[key];
}
