export default {
  dropdown: {
    selectAllBtn: "Vybrať všetko",
    fillCustomValue: "Doplň si vlastnú hodnotu.",
    noOptions: "Žiadna dostupná možnosť.",
    messages: {
      tooShort: "Zadajte aspoň { minSearchLength } znaky.",
      searching: "Vyhľadávam...",
      nothingFound: "Nič nebolo nájdené.",
    },
  },
};
