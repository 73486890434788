/**
 * A GDoc (linked below) detailing each event is available.
 * Please remember to update the GDoc if you make any changes to this file.
 *
 * https://docs.google.com/document/d/1GTKMPBclMlHsNARjeS6nYxZgd1A7vWzMgLX_G1glCiI/edit
 */

import { BuildMode } from "@/types";

export enum FacebookPixelEvent {
  CompleteRegistration = "account_registered",
}

const TrackEventCommand = "trackCustom";

export async function trackEvent(event: FacebookPixelEvent, params?: Record<string, number | string>): Promise<void> {
  const mode = import.meta.env.MODE;

  // We track events only in production.
  if (mode !== BuildMode.production) {
    return;
  }

  if (typeof window.fbq === "undefined") {
    throw new Error("No Facebook Pixel found in a page.");
  } else {
    window.fbq(TrackEventCommand, event, params ?? {});
  }
}
