export default {
  contacts: {
    heading: "Kontakty",
    introText: "Chceš se na něco zeptat nebo nám napsat nějakou radostnou novinku? Piš, piš, těšíme se.",
    headingForm: "Kontaktní formulář",
    company: "Firemní údaje",
    address: "Sídlo:",
    court: "vedená u Krajského soudu v { city }, { itemNo }",
    form: {
      name: "Jméno",
      email: "Váš e-mail",
      message: "Tvá zpráva",
      messagePlaceholder: "Dobrý den, mohu se zeptat na….",
      submit: "Odeslat zprávu",
      thankYou: "Děkujeme za tvůj dotaz, odpovíme co nejdříve!",
      back: "Zpět na formulář",
      invalidName: "Vyplň své jméno.",
      invalidMessage: "S čím ti můžeme pomoct?",
    },
  },
};
