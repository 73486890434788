import { Events } from "@/types";
import IconFontAwesome from "@/components/IconFontAwesome.vue";
import { h } from "vue";
import { ElNotification } from "element-plus";

const generalConfig = {
  position: "bottom-left",
  duration: 5000,
} as const;

export const toasts = {
  install() {
    window.eventBus.on(Events.toastError, this.addError);
    window.eventBus.on(Events.toastSuccess, this.addSuccess);
  },

  addError(message: string) {
    ElNotification({
      message,
      customClass: "error",
      icon: h(IconFontAwesome, { name: "circle-exclamation", weight: "solid" }),
      ...generalConfig,
    });
  },

  addSuccess(message: string) {
    ElNotification({
      message,
      icon: h(IconFontAwesome, { name: "circle-check", weight: "solid" }),
      customClass: "success",
      ...generalConfig,
    });
  },
};
