export default {
  profile: {
    my: {
      settingsBtn: "@:views.userSettings.heading",
    },
    public: {
      breadcrumbs: "Profil sběratele { nickname }",
    },
    noContact: "Uživatel nemá vyplněné osobní údaje.",
    sendMessage: "@:views.itemDetail.actions.sendMessage",
    sharePanel: {
      heading: "Sdílej svoji sbírku",
      toggleLabel: "Věřejný profil",
      text: "Zkopíruj si odkaz na svůj profil a sdílej ho s ostatními sběrateli. Využít ho můžeš například v prodejních skupinách nebo jako prezentaci své sbírky.",
      linkCopiedMsg: "Hurá! Odkaz na tvůj profil zkopírován do schránky. Sdílej svoji sbírku. :)",
      openPofileBtn: "Zobrazit profil",
    },
    items: {
      heading: "Zveřejněné předměty",
      emptyResult: {
        activeFilter: "Bohužel, tvému filtru neodpovídá žádná položka. Zkus vyhledávání upravit, třeba se tu tvůj vybraný kousek někde schovává.",
        myProfile: {
          heading: "Žádný předmět",
          text: "Zde ty a ostatní sběratelé uvidí všechny tvoje zveřejněné předměty, které máš ve",
          textLink: "sbírce",
          cta: "Přidat předmět",
        },
        publicProfile: {
          heading: "Žádný předmět",
          text1: "Uživatel aktuálně nemá",
          text2: "žádný zveřejněný předmět.",
        },
      },
      error: "Ops. Chyba vyhledávání.",
      offeringFor: "Nabízím za",
      filter: {
        categoryPlaceholder: "Všechny kategorie",
      },
    },
    notFound: {
      doesNotExists: "Sbírka neexistuje",
      heading: "Jak funguje Colectio",
      point1: "Měj svou sbírku na jednom místě",
      point2: "Sdílej své předměty a prodávej",
      point3: "Využívej aktuálních trendů a dat z trhu",
      point4: "Sleduj vývoj cen předmětů",
      point5: "Užij si magazín ze světa sběratelů",
      bottomBarText: "Chceš taky <br />svoji <strong>sbírku?</strong>",
      bottomBarCta: "Ano, chci!",
    },
  },
};
