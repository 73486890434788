export default {
  dashboard: {
    profile: {
      stats: {
        valueLabel: "Hodnota sbírky",
        publicItemsCountLabel: "Veřejné předměty",
        privateItemsCountLabel: "Neveřejné předměty",
        itemsCountUnit: "@:piecesUnit",
      },
    },
    myCollection: {
      heading: "@:views.myCollection.heading",
      cta: "Zobrazit sbírku",
    },
    publicItems: {
      heading: "Předměty sběratelů",
      cta: "Více předmětů",
      errorMsg: "Předměty se nepodařilo načíst.",
      errorRetryBtn: "Zkus znovu",
      tabs: {
        newest: "Nejnovější",
        forYou: "Výběr pro tebe",
      },
      forYou: {
        popupText: "Tvoje oblíbené kategorie nám pomohou lépe pochopit, o jaké typy předmětů se zajímáš.",
        popupBtn: "Nastavit oblíbené kategorie",
        infoText: "Založeno na tvých oblíbených kategoriích",
        emptyMsg: "Pro tvé oblíbené kategorie jsme bohužel nenašli žádný zajimavý předmět.",
        emptyBtn: "Změnit oblíbené kategorie",
      },
    },
    ebay: {
      heading: "@:views.ebay.heading",
      introText: {
        desktop: "@:views.ebay.introText",
        phone: "Zjisti za kolik se prodávají sběratelské předměty ve světě (eBay).",
      },
    },
    magazine: {
      heading: "@:views.magazine.heading",
      cta: "Zobrazit více",
      errorMsg: "Články se nepodařlo načíst.",
      errorRetryBtn: "Zkus znovu",
    },
  },
};
