export default {
  publicItemsList: {
    heading: "Zberateľské predmety",
    filter: {
      showBtn: "Nenašli sa žiadne položky | Zobraziť {count} predmet | Zobraziť {count} predmety | Zobraziť {count} predmetov",
      showBtnLoading: "Hľadám predmety…",
    },
    grid: {
      emptyMsg: "Bohužiaľ, tvojmu filtru nevyhovuje žiadna položka. Skús vyhľadávanie upraviť, možno sa tu tvoj vybraný kúsok niekde skrýva.",
      errorMsg: "Ups. Chyba vyhľadávania.",
      errorMsgBtn: "Obnoviť stránku",
    },
    favouriteCategories: {
      noSelected: {
        text: "@:views.dashboard.publicItems.forYou.popupText",
        cta: "@:views.dashboard.publicItems.forYou.popupBtn",
      },
      nothingFound: {
        text: "@:views.dashboard.publicItems.forYou.emptyMsg",
        cta: "@:views.dashboard.publicItems.forYou.emptyBtn",
      },
      infoText: "@:views.dashboard.publicItems.forYou.infoText",
    },
  },
};
