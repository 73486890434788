export default {
  collectors: {
    heading: "Sběratelé",
    text: "Prohlédni si portfolia ostatních sběratelů a objev, kdo má stejnou vášeň jako ty.",
    filter: {
      showBtn: "Nic nenalezeno | Zobrazit {count} sběratele | Zobrazit {count} sběratele | Zobrazit {count} sběratelů",
      showBtnLoading: "Vyhledávám sběratele…",
      sort: {
        itemCountDesc: "Nejvíce předmětů",
      },
    },
    grid: {
      emptyMsg: "Bohužel, tvému filtru neodpovídá žádný sběratel. Zkus vyhledávání upravit.",
      errorMsg: "Ops. Chyba vyhledávání.",
      errorMsgBtn: "Obnovit stránku",
    },
    card: {
      itemsSumCollection: "předmětů ve sbírce | předmět ve sbírce | předměty ve sbírce | předmětů ve sbírce",
      itemsSumCategory: "Žádný předmět | předmět | předměty | předmětů",
    },
  },
};
