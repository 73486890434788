export default {
  collection: {
    makeItemPublicDialog: {
      header: "Verejný predmet",
      text: "Tvoj predmet sa <strong>stane verejne dostupným</strong>. Ostatní používatelia ho budú môcť vyhľadať a uvidia ho aj na tvojom verejnom profile.",
      confirm: "Súhlasím",
      confirmMessage: "Predmet zverejnený.",
    },
    makeItemPrivateDialog: {
      header: "Súkromný predmet",
      text: "Tvoj predmet už <strong>nebude verejne dostupný</strong>. Ostatní zberatelia ho neuvidia ani na tvojom profile, ani v iných sekciách.",
      confirm: "Súhlasím",
      confirmMessage: "Predmet odzverejnený.",
    },
    itemList: {
      owner: "Zberateľ",
      pieces: "Množstvo",
      piecesUnit: "ks",
      offeringFor: "Ponúkam za",
      noNote: "Bez poznámky",
      detailButton: "Detail predmetu",
      visibilityLabel: "Zobrazenie predmetu",
      publishDate: "Vystavené",
    },
    itemsTable: {
      note: {
        label: "Poznámka",
        empty: "Prázdna poznámka",
      },
      delete: "Zmazať",
      tags: {
        nothingSelectedLabel: "# Všetky tagy",
        somethingSelectedLabel: "# Počet vybraných: ",
        noOptionsAvailableLabel: "Žiadne dostupné tagy.",
        searchInputPlaceholder: "Hľadať tag",
      },
    },
    itemRemoveDialog: {
      title: "Odstránenie predmetu",
      message: "Naozaj chcete vymazať svoj predmet “<strong><em>{ itemName }</em></strong>?",
      confirmButton: "Vymazať",
      ok: "Predmet bol odstránený.",
      errors: {
        notFound: "Predmet nenájdený.",
        notAllowed: "Nemáš oprávnenie predmet zmazať.",
        general: "Predmet sa nepodarilo zmazať.",
      },
    },
    stats: {
      loading: "načítavam...",
      labelValue: "Hodnota zbierky",
      labelItemsCount: "Počet predmetov",
    },
  },
};
