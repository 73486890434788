export default {
  myCollection: {
    overall: {
      breadcrumbs: "Moja zbierka",
      heading: "Moja zbierka",
      loadingCategories: "Načítavam kategórie…",
    },
    category: {
      title: "Moja zbierka: { categoryName }",
      headingText: "Detail zbierky",
      headingChart: "Vývoj hodnoty zbierky",
      collectionValue: "Hodnota zbierky",
      collectionAcquirePrice: "Pořizovacia cena zbierky",
      collectionGain: "Aktuálny zisk",
      collectionItemsCount: "Počet predmetov",
      loading: "Načítavam detaily zbierky…",
      chart: {
        priceCurrent: "Odhadovaná cena",
        priceAcquire: "Pořizovacia cena",
      },
    },
    grid: {
      heading: "Zoznam predmetov",
      empty: {
        hi: "Ahoj",
        text: "Zatiaľ nemáš vo svojej zbierke žiadny predmet",
        cta: "Pridať predmet",
      },
    },
  },
};
