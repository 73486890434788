import { RouteLocationAsRelativeGeneric, RouteLocationNormalized, RouteLocationRaw } from "vue-router";
import { useBreadcrumbsStore } from "@/store";
import { MetaTags } from "@/services/metaTags";
import { useUserStore, store as pinia } from "@/store";

const userStore = useUserStore(pinia);

export const isPublic = (route: RouteLocationNormalized): boolean => route.meta?.public === true;

export const updatePageTitle = (route: RouteLocationNormalized): void => {
  const title = route.meta.title;
  if (typeof title === "string") {
    MetaTags.setTitle(title);
  } else if (typeof title === "function") {
    MetaTags.setTitle(title());
  }
};

export function updateBreadcrumbs(route: RouteLocationNormalized): void {
  const breadcrumbs = route.meta.breadcrumbs;
  if (breadcrumbs) {
    const breadcrumbsStore = useBreadcrumbsStore();
    if (breadcrumbs.length > 0) {
      breadcrumbsStore.set(breadcrumbs);
    } else {
      breadcrumbsStore.clear();
    }
  }
}

export const createLocalizedRoute = (route: RouteLocationAsRelativeGeneric): RouteLocationRaw => {
  if (route.params === undefined || !route.params.countryCode) {
    route.params = route.params ?? {};
    route.params.countryCode = userStore.getCountryCode();
  }
  return route;
};
