abstract class Entity {
  constructor(protected id: number) {}

  public getId(): number {
    return this.id;
  }

  public setId(id: number): void {
    this.id = id;
  }
}

export { Entity };
