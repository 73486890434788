export default {
  auth: {
    layout: {
      login: "Přihlášení",
      newRegistration: "Registrace",
      closeBtnTitle: "Zavřít",
    },
    sso: {
      loginVia: "Přihlásit se přes",
      registerVia: "Registrace přes",
      facebook: "Facebook",
      google: "Google",
      loginFailed: "Přihlášení se nezdařilo.",
      loginInProgress: "Kontroluji údaje...",
      noToken: "Nepodařilo se získat user token.",
      googleLoginCreationFailed: "Nepodařilo se vytvořit Google přihlášení. Máte povoleno vytváření cookies?",
    },
    login: {
      separator: "nebo",
      placeholderPassword: "Heslo",
      placeholderEmail: "E-mail",
      lostPasswordLink: "Zapomněl jsi heslo?",
      loginButton: "Přihlásit se",
      loginButtonInProgress: "Přihlašuji...",
      passwordNotValid: "Vyplň heslo.",
      emailNotValid: "Neexistující e-mailová adresa.",
    },
    registration: {
      separator: "nebo",
      emailPlaceholder: "E-mail",
      passwordPlaceholder: "Heslo",
      registrationButton: "Registrovat se",
      registrationButtonInProgress: "Vytvářím účet...",
      emailNotValid: "Vyplň správně svoji e-mailovou adresu.",
      thankYou1: "Děkuji za tvoji registraci! Na e-mail",
      thankYou2: "jsme odeslali odkaz pro její dokončení.",
      newsletterLabel: "Informujte mě e-mailem o novinkách ze světa sběratelství.",
    },
    emailVerification: {
      okText: "Tvoje e-mailová adresa byla úspěšně ověřena.",
      okCta: "Přihlásit",
      inProgress: "Ověřuji...",
      invalidText: "Tento odkaz již bohužel není platný.",
    },
    passwordRestore: {
      fillEmail: "Zadejte e-mailovou adresu, ke které jste ztratili heslo.",
      emailPlaceholder: "E-mail",
      requestBtn: "Odeslat",
      requestBtnInProgress: "Kontroluji e-mail",
      requestSubmitted: "Na e-mail <strong>{ email }</strong> jsme odeslali odkaz pro obnovení hesla.",
      restored: "Nové heslo bylo nastaveno.",
      restoredCta: "Přihlásit",
      invalidUrl: "Bohužel tento odkaz již není platý. Vygenerujte si prosím odkaz znovu.",
      invalidUrlBtn: "Vygenerovat",
      hashCheckInProgress: "Kontroluji adresu...",
      passwordPlaceholder: "Nové heslo",
      restoreBtn: "Nastavit nové heslo",
      restoreBtnInProgress: "Nastavuji...",
    },
  },
};
