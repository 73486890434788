export default {
  gdpr: {
    heading: "Zásady ochrany osobných údajov",
    content: `
      <p>Ak spracovávame Vaše osobné údaje, vždy postupujeme v súlade s Nariadením (EÚ) 2016/679, o ochrane fyzických osôb v súvislosti so spracovaním osobných údajov a o voľnom pohybe týchto údajov a o zrušení smernice 95/46/ES (ďalej len „GDPR“) a ďalšími súvisiacimi právnymi predpismi.</p>

      <h2>Správca</h2>
      <ul>
        <li>Správcom Vašich osobných údajov je spoločnosť {companyName}, IČO: {companyTaxId}, so sídlom {companyStreet} {companyBuilding}, {companyDistrict}, {companyZip} {companyCity} (ďalej len „Spoločnosť“).</li>
      </ul>

      <h2>Informácie pre odberateľov newsletterov</h2>
      <ul>
        <li>Vaše osobné údaje budeme na základe Vašich poskytnutých súhlasov spracovávať iba za účelom zasielania marketingových oznámení, najmä newsletterov, alebo Vás budeme informovať o udalostiach v oblastiach, ktorých sa naša činnosť týka.</li>
        <li>Osobným údajom spracovávaným pre účel uvedený v predchádzajúcom bode je Vaša e-mailová adresa, ktorú ste nám na základe svojho súhlasu sami poskytli a na ktorú Vám budeme vyššie uvedené oznámenia zasielať.</li>
        <li>Vašu e-mailovú adresu budeme spracovávať po dobu trvania Vášho súhlasu. Poskytnutie Vášho súhlasu je úplne dobrovoľné a môžete ho kedykoľvek jednoducho odvolať zaslaním požiadavky na nižšie uvedenú kontaktnú adresu alebo kliknutím na odkaz, ktorý zvyčajne nájdete v zaslanom marketingovom oznámení.</li>
        <li>K Vašej e-mailovej adrese budú mať prístup iba poverení zamestnanci Spoločnosti, ktorí sú viazaní mlčanlivosťou a poučení o záväzkoch vyplývajúcich z ochrany osobných údajov.</li>
      </ul>

      <h2>Užívateľský účet</h2>
      <ul>
        <li>Vaše osobné údaje budeme spracovávať tiež na základe registrácie Vášho užívateľského účtu a prihlásením do služby na našich webových stránkach.</li>
        <li>Osobným údajom spracovávaným pre tento účel je Vaša e-mailová adresa, pod ktorou ste sa zaregistrovali a pod ktorou sa prihlasujete. V nastavení môžete tiež nahrať profilový obrázok a uviesť Vaše meno, priezvisko, adresu, telefónne číslo, URL vašich sociálnych sietí, dátum narodenia a pohlavie, aby bol Váš profil kompletný.</li>
        <li>Na vytvorenie zákazníckeho účtu a následné prihlasovanie k Vášmu účtu môžete využiť aj prístup z Vášho účtu na Google alebo z platforiem spoločnosti Meta (t.j. zo sociálnych sietí Facebook alebo Instagram). V takom prípade nebudete musieť svoje údaje vyplňovať ručne a my od spoločnosti Meta alebo Google získame Vaše osobné údaje v takom rozsahu, v akom poskytnete svoj súhlas, minimálne však e-mailovú adresu, ktorá je nevyhnutná na vytvorenie Vášho užívateľského účtu.</li>
        <li>Osobné údaje podľa tohto článku budeme spracovávať po dobu trvania Vašej registrácie na našich webových stránkach v súlade s čl. 6 ods. 1 písm. b) GDPR.</li>
        <li>Váš profil bude verejný. Ak však budete chcieť, aby bol Váš profil iba súkromný, môžete to zmeniť v nastavení svojho užívateľského účtu.</li>
      </ul>
      <h2>Tvorba zbierok</h2>
      <ul>
        <li>V rámci Vášho profilu máte možnosť udržiavať a spravovať svoju zbierku zberateľských predmetov online, a to pridaním zberateľských predmetov prostredníctvom našej databázy, ukladaním údajov a nahrávaním vlastných obrázkov týchto zberateľských predmetov. Pri tom evidujeme nasledujúce údaje:
          <ul>
            <li>Parametre a hodnoty, typ, značka, model, počet kusov, kategória a stav zberateľského predmetu.</li>
          </ul>
          </li>
          <li>Môžete tiež uviesť nákupnú cenu, čas a miesto nákupu a nahrať obrázky svojich zberateľských predmetov. Tieto informácie sú však dobrovoľné.</li>
          <li>Uvedené osobné údaje budeme spracovávať pre nasledujúce účely:
            <ul>
              <li>pre dokumentáciu a odhad hodnoty vašej osobnej zbierky,</li>
              <li>pre zaznamenanie vášho záujmu o jednotlivé zberateľské predmety, ktoré ešte nevlastníte,</li>
              <li>pre štatistické vyhodnotenie Vašich zberateľských predmetov a</li>
              <li>pre rozširovanie nášho produktového katalógu o zberateľské predmety, ktoré sme dosiaľ nepoznali.</li>
            </ul>
          </li>
          <li>U nami zhromažďovaných údajov nejde o osobné údaje, ale iba o údaje vzťahujúce sa k osobám. Osobné údaje sú údaje bez priamej osobnej referencie, z ktorých je možné odvodiť osobu.</li>
          <li>Pri spracovaní osobných údajov podľa tohto článku vychádzame z čl. 6 ods. 1 písm. b) GDPR a nášho oprávneného záujmu v súlade s čl. 6 ods. 1 písm. f) GDPR.</li>
          <li>Oprávnený záujem tu spočíva vo využívaní našej databázy zberateľských predmetov ako zdroja informácií, v ktorom môžeme štatistickým vyhodnotením údajov rozšíriť naše know-how o aktuálnej situácii na trhu a do budúcna podľa potreby optimalizovať naše služby.</li>
          <li>Vaša zbierka zberateľských predmetov je verejná spolu s Vaším profilom. Ak si prajete mať zbierku iba v súkromnom režime, môžete tak jednoducho urobiť a zmeniť nastavenia v rámci svojho užívateľského účtu.</li>
      </ul>
      <h2>Využívanie správ</h2>
      <ul>
        <li>Ako registrovanému užívateľovi Vám ponúkame možnosť komunikovať s nami a iným užívateľom prostredníctvom správ na našej webovej stránke.</li>
        <li>Komunikácia prostredníctvom správ vždy prebieha medzi Vami, Vaším komunikačným partnerom a nami. V rámci používania našich interných správ automaticky a ručne skenujeme a analyzujeme Vaše odoslané správy. To robíme za účelom
          <ul>
            <li>prevencie podvodov,</li>
            <li>odhaľovania nezákonných činností a porušovania našich zásad fungovania a</li>
            <li>zlepšovania komunikácie a starostlivosti o zákazníkov.</li>
          </ul>
        </li>
        <li>Spracovanie osobných údajov podľa tohto článku sa zakladá na našich oprávnených záujmoch podľa čl. 6 ods. 1 písm. f) GDPR. Spracovanie osobných údajov pre vyššie uvedené účely platí podľa GDPR ako predtým uznaný oprávnený záujem.</li>
        <li>Svoje zaslané a prijaté správy môžete spravovať sami a na požiadanie ich nechať nami vymazať. V prípade pokusu o podvod alebo protiprávneho konania môžeme príslušné správy na základe našich oprávnených záujmov podľa čl. 6 ods. 1 písm. f) GDPR aj napriek Vašej žiadosti o vymazanie naďalej uchovávať na účely dôkazov a uplatnenia, výkonu alebo obhajoby zákonných nárokov.</li>
      </ul>
      <h2>O súboroch cookies</h2>
      <ul>
        <li>Naše webové stránky využívajú súbory cookies. Cookies sú malé súbory uložené vo Vašom počítači alebo mobilnom zariadení webovými stránkami. Soubory cookies umožňujú webovým stránkam zabezpečiť základné funkcie webu, ukladať nastavenia stránok, analyzovať prevádzku na stránkach, zhromažďovať informácie pri prehliadaní (vrátane Vašej IP adresy) a poskytovať cielený obsah a marketingovú komunikáciu.</li>
        <li>Na našich webových stránkach používame nasledujúce typy cookies:
            <ul>
                <li>Technické cookies sú tie, ktoré musíme na Vaše zariadenie uložiť, aby naše stránky mohli správne fungovať, z tohto dôvodu sa nevyžaduje Váš súhlas.</li>
                <li>Štatistické cookies využívame na optimalizáciu webových stránok pre našich užívateľov, vďaka ktorým získavame prehľad o používaní našich webových stránok. K používaniu tohto typu cookies od Vás žiadame súhlas.</li>
                <li>Marketingové cookies slúžia na zhromažďovanie údajov o tom, ako používate web za účelom poskytovania relevantných marketingových oznámení rôznymi marketingovými kanálmi. I k používaniu týchto cookies žiadame o Váš súhlas.</li>
            </ul>
        </li>
        <li>Aké cookies používame? <br />
          <table>
            <tr>
              <th>Názov</th>
              <th>Typ</th>
              <th>Kto ich využíva</th>
              <th>Expirácia</th>
              <th>Účel</th>
            </tr>
            <tr>
              <td>_ga</td>
              <td>Analytické</td>
              <td>Google Analytics</td>
              <td>2 roky</td>
              <td>Používajú sa na podporu optimalizácie internetových stránok tým, že umožňujú zhromažďovať všeobecné štatistické informácie o využívaní stránok.</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>Analytické</td>
              <td>Google Analytics</td>
              <td>1 deň</td>
              <td>Používajú sa na podporu optimalizácie internetových stránok tým, že umožňujú zhromažďovať všeobecné štatistické informácie o využívaní stránok.</td>
            </tr>
            <tr>
              <td>_utm.gif</td>
              <td>Analytické</td>
              <td>Google Analytics</td>
              <td>Pri zavretí prehliadača</td>
              <td>Používajú sa na podporu optimalizácie internetových stránok tým, že umožňujú zhromažďovať všeobecné štatistické informácie o využívaní stránok.</td>
            </tr>
            <tr>
              <td>dsr_swli</td>
              <td>Technické</td>
              <td>{domain}</td>
              <td>1 rok</td>
              <td>Vylepšenie užívateľského zážitku počas prihlasovacieho procesu.</td>
            </tr>
          </table><br />
        </li>
        <li>Nastavenia súborov cookies máte pod Vašou kontrolou. Môžete sa rozhodnúť, či povoliť alebo zakázať rôzne typy súborov cookies na Vašom zariadení. Ak sa rozhodnete zmeniť uložené nastavenia týchto súborov, môžete ich kedykoľvek zmeniť pomocou tlačidla "Nastavenia cookies" umiestneného v pätičke. Cookies tiež môžete vymazať alebo zablokovať priamo vo Vašom prehliadači. V takom prípade potom naše stránky nemusia fungovať úplne správne.</li>
      </ul>
      <h2>Vaše práva súvisiace so spracovaním osobných údajov</h2>
      <ul>
        <li>Pokiaľ ide o Vaše údaje, máte nasledujúce práva:
          <ul>
            <li>Právo na informácie, najmä máte právo vedieť, či, prečo a akým spôsobom spracovávame Vaše osobné údaje.</li>
            <li>Právo na prístup k Vašim osobným údajom, ktoré sú nám známe.</li>
            <li>Právo na opravu, doplnenie v prípade, že zistíte, že o Vás spracovávame nepresné alebo nesprávne osobné údaje.</li>
            <li>Právo na vymazanie, ak sú osobné údaje už nepotrebné, odvoláte Vami udelený súhlas, vznesiete námietky proti spracovaniu, osobné údaje spracovávame protiprávne alebo musia byť osobné údaje vymazané na splnenie právnej povinnosti.</li>
            <li>Právo na obmedzenie spracovania Vašich osobných údajov, kedy na Vašu žiadosť môžeme za určitých právnymi predpismi stanovených podmienok obmedziť nakladanie s Vašimi osobnými údajmi.</li>
            <li>Právo na prenosnosť Vašich osobných údajov k inému správcovi.</li>
            <li>Právo na námietku proti spracovaniu osobných údajov, ak sú osobné údaje spracovávané pre naše oprávnené záujmy.</li>
          </ul>
        </li>
        <li>Na uplatnenie práv uvedených v predchádzajúcom bode nás prosím kontaktujte na nižšie uvedenú adresu. Máte-li výhrady proti tomu, ako nakladáme s Vašimi osobnými údajmi, radi by sme sa o tom dozvedeli. Máte však právo podať sťažnosť na dozorný úrad, teda na Úrad pre ochranu osobných údajov, alebo sa domáhať súdnej ochrany.</li>
      </ul>

      <h2>Kontaktné údaje</h2>
      <p>
        {companyName}<br />
        {companyStreet} {companyBuilding}, {companyZip} {companyCity}<br />
        {domain}<br />
        {email}
      </p>

    `,
  },
};
