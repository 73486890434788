import { Website } from "@/config";
import db64 from "db64";

const DbName = Website.domain;
const StoreName = "default";

let db: ReturnType<typeof db64.use>;

async function getDb() {
  if (!db) {
    await db64.create(DbName, [StoreName]);
    db = db64.use(DbName, StoreName);
  }

  return db;
}

export const IndexedDb = {
  get: async (entryName: string) => {
    const db = await getDb();
    const found = await db.getEntries(entryName);

    if (found[entryName]) {
      return found[entryName];
    } else {
      return undefined;
    }
  },

  set: async (entryName: string, entryValue: unknown) => {
    const db = await getDb();
    return db.set(entryName, entryValue);
  },

  delete: async (entryName: string) => {
    const db = await getDb();
    return db.delete(entryName);
  },
};
